import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/fr'

export const init = (container) => {
    container.querySelectorAll('textarea').forEach((el) => {
        ClassicEditor.create(el, {
            language: 'fr',
            // Remove a plugin from the default setup.
            removePlugins: ['Heading', 'Link', 'CKFinder'],
            toolbar: ['bold', 'italic', 'bulletedList', 'numberedList']
        })
            .then((editor) => {
                // keep textarea in sync
                editor.model.document.on('change:data', () => el.innerHTML = editor.getData())
            })
            .catch((error) => {
                console.error(error)
            })
    })
}
