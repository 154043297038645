import flatpickr from 'flatpickr'
import { TempusDominus } from '@eonasdan/tempus-dominus'
import { French } from 'flatpickr/dist/l10n/fr.js'
import cleanDatePlugin from './flatpickr.plugin.cleanDate'
import * as utils from '~/utils/base'

// see: https://flatpickr.js.org/options/
let settings = {
    dateFormat: 'Y-m-d',
    time_24hr: true,
    // allow HTML5 validation required attribute to work
    // see: https://github.com/flatpickr/flatpickr/issues/892#issuecomment-536235157
    allowInput: false,
    altInput: true,
    onReady: (selectedDates, dateStr, instance) => {
        instance.input.onkeypress = () => false
    }
}
let locale = document.querySelector('body').dataset.locale
if (locale === 'fr') {
    settings = utils.merge(settings, {
        locale: French,
        altFormat: 'd/m/Y'
    })
}
if (locale === 'en') {
    settings = utils.merge(settings, {
        altFormat: 'Y-m-d'
    })
}
export const settingsDefault = settings

const eraseBtn = new cleanDatePlugin({
    icon: "<i class='fas fa-eraser'></i>",
    toInitial: false,
    tooltip: 'Clean date'
})

export const initCalendars = (contentEl) => {
    contentEl.querySelectorAll('input.flatpickr-datetimepicker').forEach((el) => {
        flatpickr(
            el,
            utils.merge(settingsDefault, {
                enableTime: true,
                altFormat: settingsDefault.altFormat + ' H:i',
                dateFormat: 'Y-m-d H:i',
                // enable: el.dataset.allowedDates ? el.dataset.allowedDates.split(',') : null,
                plugins: el.disabled ? [] : [eraseBtn]
            })
        )
    })
    contentEl.querySelectorAll('input.flatpickr-datepicker').forEach((el) => {
        flatpickr(
            el,
            utils.merge(settingsDefault, {
                // enable: el.dataset.allowedDates ? el.dataset.allowedDates.split(',') : null,
                plugins: el.disabled ? [] : [eraseBtn]
            })
        )
    })
    contentEl.querySelectorAll('input.flatpickr-rangepicker').forEach((el) => {
        flatpickr(el, utils.merge(settingsDefault, { mode: 'range' }))
    })

    // Listen to tempusdominus (datetimepicker)
    // See: https://getdatepicker.com/6
    contentEl.querySelectorAll('input.tempusdominus').forEach((el) => {
        // Default conf
        let tdConf = {}

        // When a class '...-timepicker' is set by the form, then only display the clock
        if (el.classList.contains('tempusdominus-timepicker')) {
            tdConf = {
                display: {
                    icons: {
                        type: 'icons',
                        time: 'fa fa-clock',
                        date: 'fa fa-calendar',
                        up: 'fa fa-arrow-up',
                        down: 'fa fa-arrow-down',
                        previous: 'fa fa-chevron-left',
                        next: 'fa fa-chevron-right',
                        today: 'fa fa-calendar-check',
                        clear: 'fa fa-trash',
                        close: 'fa fa-xmark'
                    },
                    viewMode: 'clock',
                    components: {
                        decades: false,
                        year: false,
                        month: false,
                        date: false,
                        hours: true,
                        minutes: true,
                        seconds: false
                    }
                },
                localization: {
                    hourCycle: 'h23',
                    format: 'HH:mm',
                },
                useCurrent: false
            }
        }

        new TempusDominus(el, tdConf)
    })
}
