import { Controller } from '@hotwired/stimulus'
import notie from 'notie'

export default class extends Controller {
    /**
     * Create controller
     * @param {Context} props
     */
    constructor(props) {
        super(props)
        this.dropzone = props.scope.element
        this.container = this.dropzone.nextElementSibling.nextElementSibling
        this.formName = this.container.closest('form').name
        this.fieldName = this.dropzone.querySelector('input').id.split('__')[1]
        this.index = this.container.querySelectorAll('.list-group-item').length
        this.maxFilesize = 2000000 // 2 Mo
        this.totalFileSize = 0
    }

    _dispatchEvent(name, payload = null, canBubble = false, cancelable = false) {
        const userEvent = document.createEvent('CustomEvent')
        userEvent.initCustomEvent(name, canBubble, cancelable, payload)
        this.element.dispatchEvent(userEvent)
    }

    /**
     * Register events
     */
    connect() {
        this.context.scope.element.addEventListener('change', (event) => this.onInputChange(event))
        this.element.addEventListener('dropzone:changed', this._onChange.bind(this))
        this.element.addEventListener('dropzone:clear', this._onClear)

        // bind des liens de suppressions de chacun des documents
        let documents = this.container.querySelectorAll('.list-group-item')
        documents.forEach((document) => {
            let filesize = parseInt(document.querySelector('small').dataset.filesize)
            this.totalFileSize = this.totalFileSize + filesize

            document.querySelector('a.delete-link')
                .addEventListener('click', (e) => {
                    e.preventDefault()
                    this.removeDocument(document)
                })
        })
        this.updateTotalFilesize()
    }

    /**
     * Remove {CustomEvent} events
     */
    disconnect() {
        this.element.removeEventListener('dropzone:connect', this._onConnect)
        this.element.removeEventListener('dropzone:change', this._onChange)
        this.element.removeEventListener('dropzone:clear', this._onClear)
    }

    onInputChange(event) {
        if (event.target.files.length > 0) {
            Array.prototype.forEach.call(event.target.files, (file) => {
                this._dispatchEvent('dropzone:changed', file)
            })

            // simulate click on dropzone to clear results
            const eventClick = document.createEvent('HTMLEvents')
            eventClick.initEvent('click', true, true)
            eventClick.eventName = 'click'
            this.dropzone.querySelector('.dropzone-preview-button').dispatchEvent(eventClick)
        }
    }

    /**
     * Listener on connect event
     * @private
     * @param {CustomEvent} event
     */
    _onConnect(event) {}

    /**
     * Listener on change event
     * @param {CustomEvent} event
     * @private
     */
    _onChange(event) {
        const data = new FormData()
        data.append('file', event.detail)
        let filesize = event.detail.size
        let filesizeLbl = this.returnFileSize(filesize)

        if (event.detail.type !== 'application/pdf') {
            notie.alert({ type: 'warning', text: "Votre fichier n'est pas un document PDF", time: 2 })
            return
        }

        if (filesize > 512000) {
            notie.alert({ type: 'warning', text: 'Votre fichier excede la taille limite de 500 ko', time: 2 })
            return
        }

        if((this.totalFileSize + filesize) > this.maxFilesize) {
            notie.alert({ type: 'warning', text: "L'ensemble de vos pièces-jointes excèdent les 2 Mo", time: 2 })
            return
        }

        fetch('/document/upload', {
            method: 'POST',
            headers: {
                Accept: 'application/json'
            },
            body: data
        })
            .then((res) => res.json())
            .then((json) => {
                const item = document.createElement('div')
                item.classList.add('list-group-item', 'd-flex', 'justify-content-between', 'align-items-center')

                let inputName = `${this.formName}[${this.fieldName}][${this.index}]`

                // cas d'une collection,
                // on update l'index actuel par rapport aux items de la collection
                if(this.container.closest('.collection-content')) {
                    let li = this.container.closest('.collection-content')
                    let ul = li.closest('ul')
                    let index = Array.prototype.indexOf.call(
                        Array.from(ul.children).filter(li => li.className === 'collection-content'),
                        li
                    )
                    inputName = `${this.formName}[${ul.className}][${index}][${this.fieldName}][${this.index}]`
                }

                const input = document.createElement('input')
                input.setAttribute('type', 'hidden')
                input.setAttribute('name', inputName)
                input.setAttribute('value', json.file)
                item.appendChild(input)

                // const img = document.createElement('img')
                // img.setAttribute('src', '/' + json.file)
                // img.setAttribute('alt', json.name)
                // img.setAttribute('width', '50px')
                // img.classList.add('me-3')
                // item.appendChild(img)

                const i = document.createElement('i')
                i.setAttribute('class', 'fa fa-file-pdf fa-2x')
                i.setAttribute('style', 'color: #c74e4e')
                item.appendChild(i)

                const p = document.createElement('p')
                p.classList.add('mb-1')
                const filelink = document.createElement('a')
                filelink.setAttribute('href', '/' + json.file)
                filelink.setAttribute('style', 'display:block; width: 200px;')
                filelink.setAttribute('target', '_blank')
                filelink.textContent = json.name.length > 25 ? json.name.slice(0, 25) + '...' : json.name
                p.appendChild(filelink)
                item.appendChild(p)

                const small = document.createElement('small')
                small.textContent = filesizeLbl
                small.setAttribute('data-filesize', filesize)
                item.appendChild(small)

                const link = document.createElement('a')
                link.setAttribute('href', '#')
                link.innerHTML = `<span class="fas fa-times"></span>`
                link.classList.add('delete-link', 'ms-auto', 'text-danger')
                item.appendChild(link)

                this.container.appendChild(item)
                this.index++

                this.totalFileSize = this.totalFileSize + filesize
                this.updateTotalFilesize()

                link.addEventListener('click', (e) => {
                    e.preventDefault()
                    this.removeDocument(item)
                })
            })
    }

    /**
     * Listener on clear event
     * @param {CustomEvent} event
     * @private
     */
    _onClear(event) {}

    removeDocument(document) {
        let filesize = parseInt(document.querySelector('small').dataset.filesize)
        document.remove()
        this.totalFileSize = this.totalFileSize - filesize
        this.updateTotalFilesize()
    }

    updateTotalFilesize() {
        if (this.totalFileSize > 0) this.container.querySelector('p.totalDocument').classList.remove('d-none')
        else this.container.querySelector('p.totalDocument').classList.add('d-none')

        this.container.querySelector('span.totalFilesize').innerHTML = this.returnFileSize(this.totalFileSize)
    }

    returnFileSize(number) {
        if (number < 1024) {
            return number + ' octets'
        } else if (number >= 1024 && number < 1048576) {
            return (number / 1024).toFixed(0) + ' Ko'
        } else if (number >= 1048576) {
            return (number / 1048576).toFixed(0) + ' Mo'
        }
    }
}
