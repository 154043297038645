import TomSelect from 'tom-select'

async function jsonFetch(url) {
    const response = await fetch(url, {
        headers: {
            Accept: 'application/json'
        }
    })

    if (response.status === 204) {
        return null
    }

    if (response.ok) {
        const data = await response.json()
        return data
    }

    throw response
}

const setPlugins = (select) => {
    let plugins = {}
    plugins.clear_button = {
        title: 'Tout supprimer'
    }

    if (select.multiple) {
        plugins.dropdown_input = {}
        plugins.remove_button = {
            title: 'Supprimer'
        }
    }

    return plugins
}

const bind = (select) => {
    new TomSelect(select, {
        hideSelected: true,
        valueField: select.dataset.value,
        labelField: select.dataset.label,
        disabledField: select.dataset.disabled,
        searchField: select.dataset.search,
        // preserve natural sorting
        // https://tom-select.js.org/docs/#sortfield
        sortField: [{ field: '$order' }, { field: '$score' }],
        plugins: setPlugins(select),
        preload: !select.disabled, // 'focus',
        closeAfterSelect: !select.multiple,
        maxOptions: 1000, // le nb max d'options a afficher dans la liste
        load: async (query, callback) => {
            if (select.dataset.remote) {
                const url = new URL(window.location.origin + select.dataset.remote)
                const params = new URLSearchParams(url.search)
                params.append('terms', encodeURIComponent(query))
                url.search = params
                callback(await jsonFetch(url))
            }
        },
        onChange: (items) => {
            // s'il ne reste plus d'élément, on ferme la liste
            if (select.multiple && Object.values(select.tomselect.options).length === items.length)
                select.tomselect.close()
        },
        // NOTE: le rendu doit obligatoirement retourner de la donnée encapsulée par des balises HTML
        render: {
            no_results: function (data, escape) {
                return '<div class="no-results">' + select.dataset.labelNoresult + '</div>'
            },
            option: function (data, escape) {
                let option = '<div>'
                option += '<span class="title">' + escape(data[select.dataset.label]) + '</span>'
                if (data.address) {
                    if (typeof data.address === 'object') {
                        let address = data.address

                        let houseNumber = address.houseNumber ? address.houseNumber + ' ' : ''
                        option +=
                            '<span class="address">' +
                            houseNumber +
                            address.streetAddress +
                            ' - ' +
                            address.zipcode +
                            ' ' +
                            address.city +
                            '</span>'
                    } else {
                        option += '<span class="address">' + data.address + '</span>'
                    }
                }
                if (data.user && data.user.organization) {
                    option += '<span class="organization">' + data.user.organization.name + '</span>'
                }
                if (data.organization) {
                    if (typeof data.organization === 'object') {
                        option += '<span class="organization">' + data.organization.name + '</span>'
                    } else {
                        option += '<span class="organization">' + data.organization + '</span>'
                    }
                }
                option += '</div>'
                return option
            },
            item: (data, escape) => {
                if (data.hasOwnProperty('address') && data.address) {
                    let item = '<div>'
                    item += '<span class="title">' + escape(data[select.dataset.label]) + '</span>'

                    if (typeof data.address === 'object') {
                        let address = data.address

                        let houseNumber = address.houseNumber ? address.houseNumber + ' ' : ''
                        item +=
                            '<span class="address">' +
                            houseNumber +
                            address.streetAddress +
                            ' - ' +
                            address.zipcode +
                            ' ' +
                            address.city +
                            '</span>'
                    } else {
                        item += '<span class="address">' + data.address + '</span>'
                    }

                    item += '</div>'
                    return item
                }

                return '<div>' + escape(data.name) + '</div>'
            }
        }
    })

    let btnSelectAll = select.parentElement.querySelector('[data-ts-select-all]')
    if (btnSelectAll) {
        toggleSelectAll(btnSelectAll, select)
    }
}

const bindSelectTag = (select) => {
    new TomSelect(select, {
        persist: false,
        createOnBlur: true,
        create: true
    })
}

const bindSelectTagEmail = (select) => {
    new TomSelect(select, {
        plugins: ['remove_button'],
        persist: false,
        create: true,
        render: {
            item: function (data, escape) {
                // Hide email address on display (show it on hover)
                // pattern John Doe <john@doe.com>
                let capture = /^([\S\s]+)<(\S+)>$/g.exec(data.value)
                if (capture !== null) {
                    return (
                        '<div data-toggle="tooltip" data-placement="bottom" title="' +
                        capture[2] +
                        '">' +
                        capture[1] +
                        '</div>'
                    )
                }

                return '<div class="bg-danger text-light">' + escape(data.value) + '</div>'
            }
        }
    })
}


const bindSelectBasic = (select) => {
    new TomSelect(select, {
        plugins: setPlugins(select),
        sortField: {
            field: 'text',
            direction: 'asc'
        }
    })

    let btnSelectAll = select.parentElement.querySelector('[data-ts-select-all]')
    if (btnSelectAll) {
        toggleSelectAll(btnSelectAll, select)
    }
}

const toggleSelectAll = (b, select) => {
    let ts = select.tomselect
    b.addEventListener('click', () => {
        if(select.disabled) return

        if (b.dataset.tsSelectAll == 'add') {
            // on ajoute toutes les options de la liste
            let options = []
            Object.entries(ts.options).forEach(([key, option]) => {
                if (option.deleted === undefined || option.deleted === false) {
                    options.push({ id: key, sort: option.$order })
                }
            })
            let sortedOptions = options.sort((a, b) => a.sort > b.sort).map((a) => a.id)
            ts.addItems(sortedOptions)

            b.dataset.tsSelectAll = 'del'
            b.innerHTML = b.dataset.labelUnselect
            return
        }

        if (b.dataset.tsSelectAll == 'del') {
            // on retire toutes les options de la liste
            ts.clear()
            b.dataset.tsSelectAll = 'add'
            b.innerHTML = b.dataset.labelSelect
            return
        }
    })
}

export const init = (container) => {
    Array.from(container.querySelectorAll('[data-select]')).map(bind)
    Array.from(container.querySelectorAll('[data-select-basic]')).map(bindSelectBasic)
    Array.from(container.querySelectorAll('[data-select-tag]')).map(bindSelectTag)
    Array.from(container.querySelectorAll('[data-select-tag-email]')).map(bindSelectTagEmail)
}
