// Toggle spinner when submitting a form
// Spinner will replace current icon if any
// or will be append to the button if none found

let config = {
    iconClass: null
}

export const attach = (btn) => {
    btn.disabled = true
    let i = null
    if (btn.querySelector('i')) {
        i = btn.querySelector('i')
        config.iconClass = i.className
        i.className = 'fa fa-circle-notch fa-spin'
    } else {
        i = document.createElement('i')
        config.iconClass = null
        i.className = 'fa fa-circle-notch fa-spin'
        btn.appendChild(i)
    }
}
export const detach = (btn) => {
    let i = btn.querySelector('i.fa-spin')
    if (i === null) return

    if (config.iconClass === null) {
        btn.removeChild(i)
    } else {
        i.className = config.iconClass
    }
    btn.disabled = false
}
