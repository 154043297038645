let config = {
    field: {
        amount: null,
        vat: null
    },
    label: {
        vat: null
    }
}

const vatAuto = () => {
    config.field.vat.closest('.form-group').append(makeMessage(config.field.amount.dataset.vatRate, false))
    config.field.amount.addEventListener('change', () => updateVat())

    if (config.label.vat) {
        config.field.amount.closest('.form-group').append(makeMessage(false, config.label.vat.dataset.noVatRateFound))
    }
}

let makeMessage = (vatRate, error) => {
    let msg = document.createElement('label')
    msg.classList.add('help')
    if (vatRate) {
        msg.innerHTML = 'Taux de TVA appliqué : ' + vatRate * 100 + '%'
    }
    if (error) {
        msg.classList.add('text-warning')
        msg.innerHTML = error
    }
    return msg
}

const updateVat = () => {
    let amountEl = document.querySelector('[data-vat-rate]')
    let vatEl = document.querySelector('[id$="_vat"]')
    let amount = parseFloat(amountEl.value.replace(/,/, '.')) * parseFloat(amountEl.dataset.vatRate)
    vatEl.value = Number.isNaN(amount) ? '' : amount.toFixed(2)
}

const changeSign = (el) => {
    el.addEventListener('click', () => {
        let v = config.field.amount.value
        if (v != '') {
            v = v.replace('-', '').replace('+', '')
            config.field.amount.value =el.dataset.changeSign == 'negative' ? '-' + v : v
            updateVat()
        }
    })
}
export const init = (container) => {
    config.field.amount = container.querySelector('[data-vat-rate]')
    config.field.vat = container.querySelector('[id$="_vat"]')
    config.label.vat = container.querySelector('[data-no-vat-rate-found]')

    if (config.field.amount && config.field.vat) {
        vatAuto()
    }

    container.querySelectorAll('[data-change-sign]').forEach((el) => {
        changeSign(el)
    })
}
