// Import theme variables, this set the overall look and feel.
// ES6 import does not allow generating runtime path, require does
// require(`./css/app.default.scss`);
// require(`./css/app.${process.env.APP_THEME}.scss`);

import 'bootstrap'

import './js/utils/progressbar'
import './js/form/calendar'

import './js/datatable'
import './js/datatable/bootstrap4'

import './js/inspinia'

import './js/app'

// start the Stimulus application
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
// https://stimulus.hotwired.dev/handbook/installing#using-webpack-helpers
window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))
