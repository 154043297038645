import flatpickr from 'flatpickr'
import { settingsDefault } from './calendar'
import * as utils from '~/utils/base'

export const presetFormDates = (body) => {
    let modal = body.closest('.modal')

    let startAt = body.querySelector('[id$="_effectiveStartAt"]')
    if (startAt && modal.dataset.formPresetEffectiveStartAt) {
        // After setting the flat picker instance we need to remove attribute value...
        // ... so it won't revert to default value if the user decides to change the date and the form is invalid...
        let isDateTimePicker = startAt.classList.contains('flatpickr-datepicker') === false
        flatpickr(
            startAt,
            utils.merge(settingsDefault, {
                enableTime: isDateTimePicker,
                altFormat: isDateTimePicker ? settingsDefault.altFormat + ' H:i' : settingsDefault.altFormat,
                defaultDate: modal.dataset.formPresetEffectiveStartAt,
                readonly: true
            })
        )
        //startAt.flatpickr().destroy()
        startAt.setAttribute('readonly', true)
        modal.dataset.formPresetEffectiveStartAt = ''
    }

    let endAt = body.querySelector('[id$="_effectiveEndAt"]')
    if (endAt && modal.dataset.formPresetEffectiveEndAt) {
        let isDateTimePicker = endAt.classList.contains('flatpickr-datepicker') === false
        flatpickr(
            endAt,
            utils.merge(settingsDefault, {
                enableTime: isDateTimePicker,
                altFormat: isDateTimePicker ? settingsDefault.altFormat + ' H:i' : settingsDefault.altFormat,
                defaultDate: modal.dataset.formPresetEffectiveEndAt,
                readonly: true
            })
        )
        //endAt.flatpickr().destroy()
        endAt.setAttribute('readonly', true)
        modal.dataset.formPresetEffectiveEndAt = ''
    }
}
