export const initExpandChoicesSelect = (form) => {
    let formCheckboxesHolders = form.querySelectorAll('[data-form-all-checkbox-selectable]')
    if (formCheckboxesHolders) {
        // create for each data-form-all-checkbox-selectable a div that will be the toggler and bind actions to it
        formCheckboxesHolders.forEach((holder) => {
            let toggler = makeNewToggler(holder)
            // Only bind eventListener on new elements appended and not already listening elements
            if (toggler) {
                bindExpandChoicesSelect(toggler)
            }
        })
    }
}

let makeNewToggler = (holder) => {
    // Check the parent holder does not already contains a toggler
    if (holder.parentNode.querySelector('[data-form-toggle-checkbox]') == null) {
        let toggler = document.createElement('div')
        toggler.classList.add('mb-3')
        toggler.classList.add('ml-2')
        toggler.setAttribute('data-form-toggle-checkbox', '')
        toggler.setAttribute('data-action-select', 1)
        // Check if all checkboxes aren't already all selected
        toggler.innerHTML = '<a href="#">Tout sélectionner</a>'
        if (isAllCheckedState(holder)) {
            toggler.innerHTML = '<a href="#">Tout désélectionner</a>'
            toggler.setAttribute('data-action-select', 0)
        }
        holder.parentNode.prepend(toggler)
        return toggler
    }
    return null
}

let bindExpandChoicesSelect = (toggler) => {
    let checkboxes = toggler.parentNode.querySelectorAll('input')
    toggler.addEventListener('click', (e) => {
        let select = toggler.dataset.actionSelect
        if (toggler.dataset.actionSelect == 1) {
            updateCheckboxes(checkboxes, true)
            toggler.dataset.actionSelect = 0
            toggler.innerHTML = '<a href="#">Tout désélectionner</a>'
        } else {
            updateCheckboxes(checkboxes, false)
            toggler.dataset.actionSelect = 1
            toggler.innerHTML = '<a href="#">Tout sélectionner</a>'
        }
    })
}

let updateCheckboxes = (elements, state) => {
    elements.forEach((checkbox) => {
        if (!checkbox.disabled) {
            checkbox.checked = state
        }
    })
}

let isAllCheckedState = (holder) => {
    let elements = holder.querySelectorAll('input')
    let states = []
    elements.forEach((checkbox) => {
        states.push(checkbox.checked)
    })
    if (allEqual(states) && states[0] == true) {
        return true
    }
}

let allEqual = arr => new Set(arr).size == 1
