export const init = (container) => {
    // enable bootstrap tooltip if exists
    if (container.querySelector('[data-toggle="tooltip"]')) {
        $('[data-toggle="tooltip"]').tooltip()
    }
    if (container.querySelector('[data-toggle="popover"]')) {
        // let links inside the popover with focus method works
        // see: https://stackoverflow.com/questions/20299246/bootstrap-popover-how-add-link-in-text-popover
        $('body').on('mousedown', '.popover', function (e) {
            e.preventDefault()
        })
        $('[data-toggle="popover"]').popover()
    }
}
