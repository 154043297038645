import notie from 'notie'
import { initCalendars } from './calendar'
import { initChoices } from './choice'
import * as select from './select'
import * as wysiwyg from './wysiwyg'

const getClosest = (el, selector) => {
    for (; el && el !== document; el = el.parentNode) {
        if (el.matches(selector)) return el
    }
    return null
}
// Custom function to makem a styles cta button like all app buttons
let makeAddchildButton = (btn, text) => {
    btn.addClass('btn btn-cta in-light-context secondary')
    btn.removeClass('btn-cta-save')
    btn.empty()
    // Create icon section of the button
    let iconSection = document.createElement('div')
    iconSection.classList.add('d-inline-block', 'btn-cta-icon')
    let icon = document.createElement('i')
    icon.classList.add('fa', 'fa-plus')
    iconSection.append(icon)
    // Create label section of the button
    let label = document.createElement('div')
    label.classList.add('d-inline-block', 'btn-cta-label')
    label.innerHTML =text
    btn.append(iconSection)
    btn.append(label)
    return btn
}
export const initCollection = container => {
    container.querySelectorAll('[data-collection]').forEach(el => {
        if (el === null) return
        if (el.dataset.disabled) return

        let ul = getClosest(el, 'ul')
        let newLi = ul.querySelector('li')
        newLi.classList.add('text-center')
        let $addChildButton = $(newLi.querySelector('button'))
        let $newLinkLi = $(newLi)

        let dataCollection = $(el)
        let childButtonText = dataCollection[0].dataset.addText
        $addChildButton = makeAddchildButton($addChildButton, childButtonText)
        //$addChildButton.text(dataCollection[0].dataset.addText)
        let collectionHolder = $(ul)
        if (collectionHolder) {
            collectionHolder
                // add new button to top
                // .prepend($newLinkLi)
                // new button to bottom
                .after($newLinkLi)
                .data("index", collectionHolder.find(".card").length)

            $addChildButton
                .off("click")
                .on("click", () => addChildForm(dataCollection, collectionHolder, $newLinkLi))

            // add a delete link to all of the existing li elements
            // check current collection holder does not have children collection
            collectionHolder.find("li.collection-content").each(function() {
                let $deleteHolder = $(this).find(
                    dataCollection.attr("data-remove-dom-parent")
                )
                for (var i = 0; i < $deleteHolder.length; i++) {
                    if ($deleteHolder[i].innerHTML == '') {
                        addChildFormDeleteLink($(this), $($deleteHolder[i]));
                    }
                }
            })
        }
    })
}

document.addEventListener('DOMContentLoaded', () => {
    initCollection(document.body)
})

const addChildForm = ($dataCollection, $collectionHolder, $newLinkLi) => {
    let prototype = $collectionHolder.data("prototype")

    // IMPORTANT: to get current index (even for subcollection), items need to have ".card" class.
    // see initCollection for more information on how we set initial index
    let index = $collectionHolder.data("index")
    let newForm = prototype

    // You need this only if you didn't set 'label' => false in your CollectionType params
    newForm = newForm.replace(/__name__/g, index)
    newForm = newForm.replace(/__UNIQUE_KEY__/g, Math.floor(100000000 + Math.random() * 900000000))

    // increment index for next item
    $collectionHolder.data("index", index + 1)

    // accordion case
    if($collectionHolder[0].dataset !== undefined && "accordion" in $collectionHolder[0].dataset) {
        newForm = newForm.replace(/__accordion__/g, index)
        // ensure that other content are collapsed before showing the new one
        $('#'+$collectionHolder[0].closest('.accordion').id + ' .collapse').collapse('hide')
    }

    let $newFormLi = $('<li class="collection-content"></li>').append(newForm)

    // add element to the top of the list
    // $newLinkLi.after($newFormLi)
    // add element to the bottom of the list
    $collectionHolder.append($newFormLi)

    let $deleteHolder = $newFormLi.find(
        $dataCollection.attr("data-remove-dom-parent")
    )
    addChildFormDeleteLink($newFormLi, $deleteHolder)

    // init calendar on new input if any
    initCalendars($newFormLi[0])
    // init choices on new input if any
    select.init($newFormLi[0])
    initChoices($newFormLi[0])
    // init collection if any
    initCollection($newFormLi[0])
    // init wysiwyg if any
    wysiwyg.init($newFormLi[0])

    // focus input if any element has autofocus
    $newFormLi[0].querySelectorAll('input').forEach(input => input.autofocus ? input.focus() : null)

    // for payments (settlements/refunds), set the type of new form
    if ($dataCollection[0].name.includes('collection_settlement') || $dataCollection[0].name.includes('collection_refund')) {
        $newFormLi[0].querySelector('input').value = $dataCollection[0].dataset.collection
    }

    // Launch any callback function if exist. Usefull if need to interact with the child html elements
    if (typeof window.addCollectionCallback === "function") {
        window.addCollectionCallback()
    }
}

const addChildFormDeleteLink = ($childFormLi, $deleteHolder) => {
    let $removeFormButton = null

    // first element in the container
    if($deleteHolder[0].dataset !== undefined && $deleteHolder[0].dataset.deleteHtml !== undefined) {
       $removeFormButton = $($deleteHolder[0].dataset.deleteHtml)
    } else {
        $removeFormButton = $(
            '<button type="button" class="btn btn-danger"><i class="fas fa-trash"></i></button>'
        )
    }

    $deleteHolder.append($removeFormButton)
    $removeFormButton.on("click", (e) => {
        e.preventDefault()
        notie.confirm({
            text: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
            submitText: 'Oui',
            cancelText: 'Annuler',
            cancelCallback: () => { notie.alert({ type: 3, text: 'Action annulée', time: 2 }) },
            submitCallback: () => { $childFormLi.remove() }
        })
    })
}
