import Choices from 'choices.js'
import { initExpandChoicesSelect } from './expand-choices-select'
import * as checkbox from './checkbox'
import * as fetcher from '~/utils/fetcher'
import * as utils from '~/utils/base'
import notie from 'notie'
import dayjs from 'dayjs'
// import utc from 'dayjs/plugin/utc'
// import timezone from 'dayjs/plugin/timezone'
// import customParseFormat from 'dayjs/plugin/customParseFormat'
// dayjs.extend(customParseFormat)

const settingsDefault = {
    removeItemButton: false,
    searchResultLimit: 100,
    placeholder: true,
    searchChoices: true,
    // todo translations
    allowHTML: true,
    shouldSort: false,
    shouldSortItems: false,
    placeholderValue: 'Aucune sélection',
    searchPlaceholderValue: 'Rechercher...',
    itemSelectText: 'Sélectionner',
    noResultsText: 'Aucun résultat...',
    loadingText: 'Chargement...',
    noChoicesText: "Plus d'option à choisir !"
}
export const currentChoices = []
export const initChoices = (contentEl) => {
    contentEl.querySelectorAll('[data-choices]').forEach((el) => {
        // some choiceType has been overriden (ie. add expanded attribute)
        // skip those
        if (el.name === undefined) {
            return
        }

        let settingsCustom = {
            // placeholderValue: el.placeholder,
            searchPlaceholderValue:
                el.dataset.searchPlaceholder !== undefined
                    ? el.dataset.searchPlaceholder
                    : settingsDefault.searchPlaceholderValue,
            removeItemButton: el.dataset.removeItemButton !== undefined
            // callbackOnCreateTemplates: (template) => {
            //     return {
            //       item: (classNames, data) => {
            //         console.log(data, classNames)
            //         // return template(`
            //         //   <div class="${classNames.item} ${
            //         //   data.highlighted
            //         //     ? classNames.highlightedState
            //         //     : classNames.itemSelectable
            //         // } ${
            //         //   data.placeholder ? classNames.placeholder : ''
            //         // }" data-item data-id="${data.id}" data-value="${data.value}" ${
            //         //   data.active ? 'aria-selected="true"' : ''
            //         // } ${data.disabled ? 'aria-disabled="true"' : ''}>
            //         //     <span>&bigstar;</span> ${data.label}
            //         //   </div>
            //         // `);

            //         return template()
            //       },
            //     }
            // }
        }

        let dropdown = new Choices(el, utils.merge(settingsDefault, settingsCustom))
        currentChoices.push(dropdown)
        // prepend icons
        if (el.name.includes('serviceType') || el.name.includes('service')) {
            // TODO: on multiple service choices, items could appear twice (with and without icons)
            // better to deal with callbackOnCreateTemplates to append icons  instead of this custom method anyway.
            // see example above to see how we can prepend a star icon on each item.
            if (el.multiple === false) {
                prependServiceIcons(dropdown)
            }
        }

        // fetch allowed dates when service changes
        if (el.name.includes('serviceType')) {
            el.addEventListener('change', (e) => fetchAllowedDates(el))
        }

        // el.addEventListener('choice', e => {
        //      let parent = getClosest(e.target, '.columns')
        //      parent.nextElementSibling.querySelectorAll('input').forEach(input => {
        //          if (input.name.includes('code')) {
        //              input.value = e.detail.choice.customProperties.code
        //          }
        //          if (input.name.includes('price')) {
        //              input.value = e.detail.choice.customProperties.price.toString()
        //          }
        //      })
        // })
    })

    contentEl.querySelectorAll('[data-choices-remote]').forEach((el) => {
        let timer = null
        let config = {
            searchResultLimit: 15,
            searchChoices: false,
            noChoicesText: el.dataset.placeholder,
            removeItemButton: true
        }
        let dropdown = new Choices(el, utils.merge(settingsDefault, config))
        currentChoices.push(dropdown)
        el.addEventListener('search', (e) => {
            if (e.detail.value) {
                clearTimeout(timer)
                timer = setTimeout(() => {
                    dropdown.clearChoices()
                    dropdown
                        .setChoices(async () => remoteSearch(el, e.detail.value))
                        .then(() => {
                            // HACK: refocus input search, since focus has been stolen by the result list
                            let choiceInner = e.target.closest('.choices__inner').choiceInner
                            if (undefined !== choiceInner) {
                                let input = choiceInner.querySelector('input')
                                if (input !== null) input.focus()
                            }
                        })
                }, 500)
            }
        })

        // every time the dropdown is shown
        // el.addEventListener('showDropdown', e => {
        //     dropdown.setChoices(async () => remoteSearch(el, ''))
        // })

        // dropdown.config.choices.forEach(
        //     (choice) => {
        //         console.info(choice)
        //         // (choice.label = '<i class="fas fa-' + choice.value + '"></i>  ' + choice.label)
        //     })

        if (el.dataset.init !== undefined) {
            // set initial list of possible values
            dropdown.setChoices(async () => remoteSearch(el))
            // .then(() => dropdown.setChoiceByValue("2"))
        }
    })

    contentEl.querySelectorAll('#allowedDates-placeholder').forEach((el) => toggleWholeDay(el))
}

const remoteSearch = (el, terms = '') => {
    // update terms query string
    let url = new URL(window.location.origin + el.dataset.url)
    let filters = new URLSearchParams(url.search)
    filters.set('terms', terms)
    url.search = filters

    return fetch(url)
        .then((response) => fetcher.handleResponse(response))
        .then((data) =>
            data.results.map((dataset) => ({
                label: choiceLabelTemplate(el.dataset.propertyLabelTemplate, dataset),

                // label: el.dataset.propertyLabelType == 'array' ?
                //     dataset[el.dataset.propertyLabel] : utils.fetchFromObject(dataset, el.dataset.propertyLabel),
                value:
                    el.dataset.propertyValueType == 'array'
                        ? dataset[el.dataset.propertyValue]
                        : utils.fetchFromObject(dataset, el.dataset.propertyValue)
            }))
        )
}

const choiceLabelTemplate = (template, dataset) => {
    if (template == 'user') {
        let lbl = '<b>' + dataset.username + '</b><br>'
        if (dataset.user.organization != null) {
            lbl += '<i>' + dataset.user.organization.name + '</i>'
            if (dataset.user.organization.alias != null) {
                lbl += ' (' + dataset.user.organization.alias.toUpperCase() + ')'
            }
        }
        return lbl
    }

    if (template == 'place') {
        let place = dataset.place
        let address = place.address

        let lbl = '<b>' + place.name + '</b><br>'
        lbl += address.streetAddress + ' - ' + address.zipcode + ' ' + address.city

        return lbl
    }
}

const fetchAllowedDates = (el) => {
    let form = el.tagName === 'FORM' ? el : el.closest('form')
    if (form.name != 'event') {
        return
    }
    let traitantPlaceholder = el.closest('.card-body').querySelector('#traitant-placeholder')
    let allowedDatesPlaceholder = el.closest('.card-body').querySelector('#allowedDates-placeholder')

    if (el.value === '') {
        traitantPlaceholder.innerHTML = ''
        allowedDatesPlaceholder.innerHTML = ''
        return
    }

    let startTime = form.querySelector('#event_eventStartTime').value.split(':')
    let endTime = form.querySelector('#event_eventEndTime').value.split(':')
    let eventDates = form.querySelector('#event_eventDates')._flatpickr
    let selectedDates = eventDates.selectedDates

    let startAt = selectedDates[0]
    startAt.setHours(parseInt(startTime[0]))
    startAt.setMinutes(parseInt(startTime[1]))

    let endAt = selectedDates[selectedDates.length - 1]
    endAt.setHours(parseInt(endTime[0]))
    endAt.setMinutes(parseInt(endTime[1]))

    startAt = dayjs(startAt)
    endAt = dayjs(endAt)

    let uri = `/fetch/${form.dataset.type}/config/service/${el.value}/${startAt.format()}/${endAt.format()}/${form.dataset.organization}`
    // admin case for new event only
    if (form.dataset.organization === '' && form.querySelector('#event_organizer')) {
        uri = `${uri}/${form.querySelector('#event_organizer').value}`
    }

    fetch(uri)
        .then((response) => fetcher.handleResponse(response))
        .then((data) => {
            let contentHtml = document.createElement('div')
            contentHtml.innerHTML = data.view.content
            let match = el.name.match(/\[(\d)\]/)

            // replace traitant placeholder
            traitantPlaceholder.innerHTML = htmlCollectionReplace(contentHtml, '#traitant-placeholder', match[1])
            initChoices(traitantPlaceholder)

            // replace allowedDates placeholder
            allowedDatesPlaceholder.innerHTML = htmlCollectionReplace(
                contentHtml,
                '#allowedDates-placeholder',
                match[1]
            )
            toggleWholeDay(allowedDatesPlaceholder)
            // Give option to User to select/unselect checkboxes expanded list
            initExpandChoicesSelect(form)
            checkbox.init(el.closest('.card-body'))
        })
        .catch((error) => notie.alert({ type: 'error', text: error, time: 5 }))
}

// On click on a link to select all dates of a specific day (e.g. noon + dinner)
// Select all available checkbox of the day and check for attendees available
const toggleWholeDay = (container) => {
    container.querySelectorAll('[data-select-all-available-dates]').forEach((day) => {
        day.addEventListener('click', (e) => {
            let line = day.parentNode.parentNode

            line.querySelectorAll('input[type="checkbox"]').forEach((input) => {
                if (!input.disabled) {
                    input.checked = day.dataset.selectAllAvailableDates == 'add'
                }
            })

            if (day.dataset.selectAllAvailableDates == 'del') {
                day.dataset.selectAllAvailableDates = 'add'
                return
            }

            if (day.dataset.selectAllAvailableDates == 'add') {
                day.dataset.selectAllAvailableDates = 'del'
                return
            }
        })
    })
}

const htmlCollectionReplace = (contentHtml, id, index) => {
    let r = utils.replaceAll(contentHtml.querySelector(id).innerHTML, '_0_', '_' + index + '_')
    r = utils.replaceAll(r, '\\[0\\]', '[' + index + ']')
    return r
}

const prependServiceIcons = (elChoice) => {
    elChoice.config.choices.forEach(
        (choice) => (choice.label = '<i class="fas fa-' + choice.value + '"></i>  ' + choice.label)
    )
    elChoice.clearChoices()
    elChoice.setChoices(elChoice.config.choices)
}
