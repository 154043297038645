import { onComplete } from '~/datatable'
import * as fetcher from '~/utils/fetcher'

let actionZone = document.querySelector('.datatable-action-zone')
let totalElements = document.querySelector('[data-total-elements]')
let expandToAllButton = null
if (actionZone) expandToAllButton = actionZone.querySelector('[data-expand-selection-to-hidden]')
let currentRowTarget = null
let currentView = null

export const getRowSelectInput = (row) => row.querySelector('[data-select-row]')
export const getAllRows = () => document.querySelector('.dataTable tbody').querySelectorAll('tr')

export const toggleActionZone = () => {
    let secondaryContent = document.querySelector('.page-heading .secondary-actions')
    secondaryContent.style.opacity = '.3'
    getRowTarget()

    // on récupère ici la liste possible d'actions en fonction de la sélection.
    fetch('/fetch/list/actions', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            view: currentView,
            ids: getSelectedIds()
        })
    })
        .then((response) => fetcher.handleResponse(response))
        .then((data) => {
            secondaryContent.innerHTML = ''
            if (data.secondary.length > 0) {
                data.secondary.forEach((btn) => {
                    if(btn.download === true) {
                        secondaryContent.appendChild(createActionButton(btn, 'download'))
                    } else if (btn.modalInfo === true) {
                        secondaryContent.appendChild(createActionButton(btn, 'info'))
                    } else if (btn.triggerModal === true) {
                        secondaryContent.appendChild(createActionButton(btn, 'tertiary')) // tertiary means secondary with a primary effect
                    } else {
                        secondaryContent.appendChild(createActionButton(btn, 'secondary'))
                    }
                })
                secondaryContent.style.opacity = '1'
            }

            // bind click event on primary/secondary buttons actions
            onComplete(document.querySelector('.page-heading'))
        })
}

const setSelectionCount = (count) => {
    actionZone.querySelectorAll('[data-select-count]').forEach((htmlCount) => {
        htmlCount.innerHTML = '(' + count + ')'
    })
}

export const configureButtons = () => {
    actionZone.querySelectorAll('[data-select-action-url]').forEach((button) => {
        let selectionCount = document.createElement('span')
        selectionCount.setAttribute('data-select-count', '')
        button.querySelector('.btn-cta-label').append(selectionCount)
    })
}

export const getChildNodeIndex = (elem) => {
    let position = 0
    while ((elem = elem.previousSibling) != null) {
        if (elem.nodeType != Node.TEXT_NODE) position++
    }
    return position
}

export const handleActionZone = () => {
    let selectionsCount = countSelectedRows()
    if (selectionsCount == 0) {
        setExpandToAll(false)
        document.querySelector('[data-select-all]').checked = false
    }
    toggleActionZone()
    setSelectionCount(selectionsCount)
}

export const getSelectedRows = () => {
    let tbody = document.querySelector('.dataTable tbody')
    let selectedRows = []
    tbody.querySelectorAll('tr').forEach(function (row) {
        let checkbox = row.querySelector('[data-select-row]')
        if (checkbox.checked) {
            selectedRows.push(row)
        }
    })
    return selectedRows
}

export const countRows = () => document.querySelectorAll('.dataTable tbody input.datatable-checkbox').length
export const countSelectedRows = () => document.querySelectorAll('.dataTable tbody input.datatable-checkbox:checked').length

export const selectHiddenPrompt = (show) => {
    // If selection count is > to the count badge on top page,
    // prompt a button to set selection to All
    let totalLength = parseInt(totalElements.innerHTML)
    let selectedLength = countSelectedRows()

    // @to replace by a < b after develop
    if (selectedLength == totalLength && expandToAllButton && show) {
        expandToAllButton.querySelector('.btn-cta-label').innerHTML = totalLength + ' au total, sélectionner tout ?'
        toggleExpandToAllButton(false)
    } else {
        expandToAllButton.classList.remove('active')
    }
}

export const bindExpandToAllButton = () => {
    if (expandToAllButton) {
        expandToAllButton.addEventListener('click', () => {
            // Update visual count of every buttons
            setSelectionCount(parseInt(totalElements.innerHTML))
            // feed buttons data-select-all attribute
            setExpandToAll(true)
            // display success visual effect
            toggleExpandToAllButton(true)
        })
    }
}

export const setExpandToAll = (expand) => {
    actionZone.querySelectorAll('[data-select-action-url]').forEach((button) => {
        if (expand) {
            button.setAttribute('data-select-all', 1)
        } else {
            button.removeAttribute('data-select-all')
        }
    })
}

let toggleExpandToAllButton = (clicked) => {
    if (clicked) {
        expandToAllButton.classList.add('success', 'fade-out')
        expandToAllButton.querySelector('.btn-cta-label').innerHTML = 'Ajouté à la sélection'
        expandToAllButton.querySelector('.btn-cta-icon i').classList = 'fa fa-check'
        setTimeout(() => {
            expandToAllButton.classList.remove('active')
        }, 2500)
    } else {
        expandToAllButton.classList.remove('success', 'fade-out')
        expandToAllButton.querySelector('.btn-cta-icon i').classList = 'fa fa-question'
        expandToAllButton.classList.add('active')
    }
}

// en fonction de la route courante, on détermine quel est le data_target_id
const getRowTarget = () => {
    if (currentRowTarget !== null) return currentRowTarget

    let obj = {
        delivery_index: 'data-delivery-id',
        delivery_fix_index: 'data-delivery-fix-id',
        service_requested_index: 'data-service-requested-id',
        services_request_index: 'data-services-request-id',
        attendee_index: 'data-attendee-id',
        user_index: 'data-user-id',
        event_index: 'data-event-id',
        place_index: 'data-place-id',
    }
    Object.entries(obj).forEach(([key, value]) => {
        if (document.body.className.includes(key)) {
            currentView = key
            currentRowTarget = value
        }
    })

    return currentRowTarget
}

const getSelectedIds = () => {
    let ids = []
    getSelectedRows().forEach((row) => {
        let arr = {}
        let hasValues = false
        currentRowTarget.split(',').forEach((target) => {
            row.querySelectorAll('[' + target + ']').forEach((el) => {
                hasValues = true
                let key = target.replace('data-', '')
                if (key in arr) {
                    arr[key] = arr[key] + ',' + el.getAttribute(target)
                } else {
                    arr[key] = el.getAttribute(target)
                }
            })
        })

        if (hasValues) {
            ids.push(arr)
        }
    })

    return ids
}

const createActionButton = (button, buttonType) => {
    let btn = document.createElement('button')
    let selectedRowsCount = countSelectedRows()

    // primary button -> open modal
    if (buttonType == 'primary') {
        btn.dataset.modal = ''
        btn.dataset.target = '#modal-app'
        btn.dataset.title = button.title
        btn.dataset.url = button.route
        btn.className = 'btn btn-sm btn-cta in-light-context'
    }

    // secondary button -> confirm action
    if (buttonType == 'secondary') {
        btn.dataset.selectActionUrl = button.route
        btn.dataset.rowTarget = button.target
        btn.className = 'btn btn-cta secondary in-light-context w-auto m-1'
    }

    // secondary button -> open modal
    if (buttonType == 'tertiary') {
        btn.dataset.modal = ''
        btn.dataset.target = '#modal-app'
        btn.dataset.title = button.title
        btn.dataset.url = button.route
        btn.className = 'btn btn-cta secondary in-light-context w-auto m-1'
    }

    // secondary button -> download content
    if (buttonType == 'download') {
        btn.dataset.actionNoConfirm = null
        btn.dataset.rowTarget = button.target
        btn.dataset.url = button.route
        btn.className = 'btn btn-cta secondary in-light-context w-auto m-1'
    }

    // secondary button -> popup info before confirm action
    if(buttonType == 'info') {
        btn.dataset.infoWithConfirm = null
        btn.dataset.infoRoute = button.route
        btn.dataset.infoTitle = button.modalInfoTitle
        btn.dataset.infoBody = button.modalInfoBody
        btn.dataset.infoBtnLabel = button.modalInfoBtnLabel
        btn.dataset.rowTarget = button.target
        btn.dataset.confirmText = button.confirmText
        btn.dataset.count = selectedRowsCount
        btn.className = 'btn btn-cta secondary in-light-context w-auto m-1'
    }

    let icon = document.createElement('div')
    icon.className = 'd-inline btn-cta-icon'
    let i = document.createElement('i')
    i.className = 'fa fa-' + button.icon
    icon.appendChild(i)

    let label = document.createElement('div')
    label.className = 'd-inline-block btn-cta-label'
    label.innerHTML = button.label
    label.dataset.label = button.label
    label.dataset.count = selectedRowsCount
    let small = document.createElement('small')
    small.innerHTML = selectedRowsCount
    small.className = 'badge badge-info'
    label.appendChild(small)

    btn.appendChild(icon)
    btn.appendChild(label)

    return btn
}
