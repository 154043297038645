import { initCollection } from '~/form/collection'
import { initCalendars } from '~/form/calendar'
import { initChoices } from '~/form/choice'
import { toggleFormOptions } from '~/form/options'
import { presetFormDates } from '~/form/preset-dates'
import { initExpandChoicesSelect } from '~/form/expand-choices-select'
import * as wysiwyg from '~/form/wysiwyg'
import * as select from '~/form/select'
import * as checkbox from '~/form/checkbox'
import * as vat from '~/form/vat'

/**
 * Init basic form elements found in the current form
 * (dropdown, select, textarea, ...)
 */
const baseElements = (form) => {
    initCollection(form)
    initCalendars(form)
    initChoices(form)
    toggleFormOptions(form)
    presetFormDates(form)
    // Give option to User to select/unselect checkboxes expanded list
    initExpandChoicesSelect(form)

    wysiwyg.init(form)
    select.init(form)
    checkbox.init(form)

    vat.init(form)
}

/**
 * Init special logic for current form
 */
const specificForms = (form) => {
    if(form.name === 'user') {
        import(/* webpackChunkName: "user" */ './user').then((f) => f.init(form))
    }

    if (form.name === 'event') {
        import(/* webpackChunkName: "event" */ './event').then((f) => f.init(form))
    }

    if (form.name === 'invoice_fix') {
        import(/* webpackChunkName: "invoice_fix" */ './invoice_fix').then((f) => f.init(form))
    }

    // See references to AddressCustomType::class
    if (['organization', 'provider', 'place', 'address'].includes(form.name)) {
        import(/* webpackChunkName: "address" */ './address').then((f) => f.init(form))
    }

    if (form.name === 'batch_services_request_start') {
        import(/* webpackChunkName: "batch_services_request_start" */ './batch_services_request_start').then((f) =>
            f.init(form)
        )
    }

    if (form.name === 'batch_services_request_form') {
        import(/* webpackChunkName: "services_request" */ './services_request').then((f) => f.init(form))
    }

    if (['delivery', 'batch_delivery_form', 'batch_delivery_awaiting_invoice_form'].includes(form.name)) {
        import(/* webpackChunkName: "delivery" */ './delivery').then((f) => f.init(form))
    }

    if (form.name === 'cli') {
        import(/* webpackChunkName: "cli" */ './cli').then((f) => f.init(form))
    }
}

export const init = (modalBody) => {
    let form = modalBody.parentElement.querySelector('form')

    // needed only if a form is found in the modal body
    if (form) {
        baseElements(form)
        specificForms(form)
    }
}
