import notie from 'notie'
import { refreshDataTable } from '~/datatable'

// Handle click on buttons or links
export const handleClick = (input) => {
    input.addEventListener('click', e => {
        e.preventDefault()

        input.disabled = true

        let url = e.target.dataset.url

        // if target element has no dataset check input directly
        // this happens if the target is not an anchor but a button
        if (undefined === url) {
            url = input.dataset.url
        }

        // if target element has no dataset, check the closest link
        if (undefined === url) {
            let link = e.target.closest('a')
            url = link.dataset.url
        }

        // hide tooltip if it was triggered
        // let hasTooltip = input.dataset.toggle !== undefined && input.dataset.toggle === 'tooltip'
        // if (hasTooltip) {
        //     $(input).tooltip('hide')
        // }

        fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                'Content-Type': 'application/json'
            },
        }).then(response => response.json().then(result => {
            if (result.success) {
                // display flash message
                if (result.notification) {
                    notie.alert({ type: result.notification.type, text: result.notification.text, time: 5 })
                }

                // replace button html if any
                if (result.html) {
                    let el = createElementFromHTML(result.html)
                    input.replaceWith(el)
                    handleClick(el)
                    // if (hasTooltip) {
                    //     $(el).tooltip()
                    // }
                } else {
                    input.disabled = false
                }

                // delete action element when no future action could be possible
                if (result.deleteElement) {
                    input.parentNode.removeChild(input)
                }

                // redirect if any
                if (result.redirectUrl) {
                    let appDomain = window.location.href.match(/:\/\/(.[^/]+)/)[1]
                    let redirectedDomain = result.redirectUrl.match(/:\/\/(.[^/]+)/)[1]
                    // in new window if external url
                    if (appDomain !== redirectedDomain) {
                        let win = window.open(result.redirectUrl)
                        win.focus()

                    } else {
                        window.location.href = result.redirectUrl
                    }
                }

                if (result.refreshDataTable) {
                    refreshDataTable()
                }
            }
        }))
    })

    const createElementFromHTML = (html) => {
        let div = document.createElement('div');
        div.innerHTML = html.trim();
        // Change this to div.childNodes to support multiple top-level nodes
        return div.firstChild;
    }
}
