export const handleResponse = (response) => {
    // on redirige si on nous le demande
    // exemple la session a expiré
    if (response.redirected) {
        window.location.replace(response.url)
    }

    // fetch ne catch pas les statuts HTTP
    // on vérifie que la réponse est correct sinon on lance une exception
    // (attrappable dans le catch du fetch)
    if (response.status >= 200 && response.status <= 299) {
        let headers = response.headers

        // On vérifie également le content-type de la réponse.
        if (response.headers.get('Content-Type') === 'text/csv; charset=UTF-8') {
            response.text().then((text) => {
                let filename = 'test.csv'
                let disposition = headers.get('Content-Disposition')
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                      filename = matches[1].replace(/['"]/g, '');
                    }
                }
                const windowUrl = window.URL || window.webkitURL
                const blob = new Blob([text], { type: headers.get('Content-Type') })
                const url = windowUrl.createObjectURL(blob)

                const a = document.createElement('a')
                a.href = url;
                a.download = filename
                a.click()
                setTimeout(() => windowUrl.revokeObjectURL(url), 0)
                // window.location = url
            })
        } else {
            return response.json()
        }

    } else {
        // console.log(response)
        // TODO le text est un peu (trop) basique (Error: Forbidden)
        // on doit pouvoir le customiser via https://symfony.com/doc/current/security/access_denied_handler.html
        throw Error(response.statusText)
    }
}
