export const init = (container) => {
    if (container.querySelector('.tab-pane')) {
        // select the first tab containing error
        let tabId = container.querySelector('.invalid-feedback')
        if (tabId) {
            $('#v-pills-tab a#' + tabId.closest('div.tab-pane').id + '-tab').tab('show')

            // scroll to the error if needed
            container
                .querySelector('.invalid-feedback')
                .scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })

            // select the tab explicitly set from the data attribute tab
        } else {
            let tabNumber = container.querySelector('.tab-content').dataset.tab
            $('#v-pills-tab a:nth-child(' + tabNumber + ')').tab('show')
        }
    }
}
