export const toggleFormOptions = (modalForm) => {
    modalForm.querySelectorAll('[data-form-options-toggler]').forEach((toggler) => {
        toggler.addEventListener('click', () => {
            modalForm.querySelectorAll('[data-form-options-content]').forEach(formOptions => {
                formOptions.classList.remove('d-none')
            })
            toggler.classList.add('d-none')
        })
    })
}
