import * as fetcher from '~/utils/fetcher'

let config = {
    // seuil minimum en sec. pour déclencher un rafraichissement manuelle de notif
    minRefresh: 10,
    notifEl: null,
    lastRefresh: null,
    notifCount: null
}

const canRefresh = () => {
    if (config.lastRefresh === null) return true

    let d = new Date()
    let seconds = (d.getTime() - config.lastRefresh.getTime()) / 1000
    return seconds > config.minRefresh
}

const refreshNotif = (open) => {
    fetch('/notification/refresh', {
        credentials: 'include',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json'
        }
    })
        .then((response) => fetcher.handleResponse(response))
        .then((notifications) => {
            let shouldBell = notifications.alerts.length > 0 && config.notifCount !== notifications.alerts.length

            config.notifCount = notifications.alerts.length
            config.lastRefresh = new Date()
            config.notifEl.innerHTML = notifications.html.content

            let bell = config.notifEl.querySelector('.notification-bell > i')
            bell.setAttribute('data-count', config.notifCount)

            if (shouldBell) {
                bell.classList.remove('notify')
                bell.classList.add('notify')
                bell.classList.add('show-count')
            }

            if (open) {
                config.notifEl.querySelector('ul').classList.add('show')
            }
        })
}

const callback = (mutationList, observer) => {
    mutationList.forEach(function (mutation) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            // la notif est ouverte et on peut rafraîchir
            if (mutation.target.classList.contains('show') && canRefresh()) {
                refreshNotif(true)
            }
        }
    })
}

const bindContainer = () => {
    // on écoute l'ouverture du panneau de notification
    const observer = new MutationObserver(callback)
    observer.observe(config.notifEl, { attributes: true })
    refreshNotif(false)
}

export const init = () => {
    config.notifEl = document.querySelector('nav.navbar-static-top [data-notification]')

    if (config.notifEl) {
        bindContainer()
    }
}
