import notie from 'notie'
import { refreshDataTable } from '~/datatable'
import * as fetcher from '~/utils/fetcher'
import * as selectUtils from './select-utils'

let lastSelected = null
let activeClass = 'selected'
let selectAll = null

/* Click on the header checkbox "select/unselect all" */
export const toggleAll = (el) => {
    if (selectUtils.countRows() > 0) {
        lastSelected = null

        selectUtils.getAllRows().forEach((row) => {
            let checkbox = row.querySelector('[data-select-row]')
            if (checkbox.checked == el.checked) {
                return
            }

            checkbox.checked = el.checked
            row.classList.remove(activeClass)
            if (el.checked) {
                row.classList.add(activeClass)
            }
        })

        // toggle sub actions
        selectUtils.toggleActionZone()

        // selectUtils.handleActionZone()

        // if (el.checked) {
        //     selectUtils.selectHiddenPrompt(true)
        // } else {
        //     selectUtils.selectHiddenPrompt(false)
        //     selectUtils.setExpandToAll(false)
        // }
    }
}

/* "shift + click" on a checkbox */
export const toggleExtend = (toggler) => {
    let indexes = [
        selectUtils.getChildNodeIndex(lastSelected.closest('tr')),
        selectUtils.getChildNodeIndex(toggler.closest('tr'))
    ]
    // Make sure the smaller index of selection is always on first position in array
    indexes.sort((a, b) => a - b)
    // Get all rows of the table
    let rows = selectUtils.getAllRows()
    // Treat only the checkbox value regarding action and current interval selection
    for (var i = indexes[0]; i <= indexes[1]; i++) {
        let input = selectUtils.getRowSelectInput(rows[i])
        switch (lastSelected.checked ? 'select' : 'unselect') {
            case 'select':
                input.checked = true
                rows[i].classList.add(activeClass)
                break
            case 'unselect':
                input.checked = false
                rows[i].classList.remove(activeClass)
                break
        }
    }
    // selectUtils.handleActionZone()
    selectUtils.toggleActionZone()
}

/* "click" on the checkbox of a specific row */
export const toggleRow = (toggler) => {
    lastSelected = toggler
    let row = toggler.closest('tr')
    if (toggler.checked) {
        row.classList.add(activeClass)
    } else {
        row.classList.remove(activeClass)
        // selectUtils.selectHiddenPrompt(false)
        selectUtils.setExpandToAll(false)
        selectAll.checked = false
    }
    selectUtils.toggleActionZone()
    // selectUtils.handleActionZone()

    return lastSelected
}

/* selection then "click" on a button of the action zone */
const batchSelectionInit = () => {
    let actions = document.querySelectorAll('[data-select-action-url]')
    actions.forEach((b) => {
        b.removeEventListener('click', confirmBatchSel)
        b.addEventListener('click', confirmBatchSel)
    })

    let actionsWithInfoConfirm = document.querySelectorAll('[data-info-with-confirm]')
    actionsWithInfoConfirm.forEach((b) => {
        b.removeEventListener('click', showInfoBeforeConfirmBatchSel)
        b.addEventListener('click', showInfoBeforeConfirmBatchSel)
    })

    let actionsWithoutConfirm = document.querySelectorAll('[data-action-no-confirm]')
    actionsWithoutConfirm.forEach((b) => {
        b.removeEventListener('click', uploadBatchSel)
        b.addEventListener('click', uploadBatchSel)
    })
}

// construction de la modale d'avertissement
const showInfoBeforeConfirmBatchSel = (e) => {
    let action = e.currentTarget

    let modalTitle = document.querySelector('#modal-info .modal-title')
    modalTitle.innerHTML = action.dataset.infoTitle

    let modalBody = document.querySelector('#modal-info .modal-body')
    modalBody.innerHTML = action.dataset.infoBody

    let b = document.querySelector('#modal-info .modal-footer > button')
    b.innerHTML = action.dataset.infoBtnLabel
    b.dataset.selectActionUrl = action.dataset.infoRoute
    b.dataset.rowTarget = action.dataset.rowTarget
    b.dataset.confirmText = action.dataset.confirmText
    b.dataset.count = action.dataset.count

    b.removeEventListener('click', confirmInfoBatchSel)
    b.addEventListener('click', confirmInfoBatchSel)

    $('#modal-info').modal('show')
}

// confirmation simple
const confirmBatchSel = (e) => {
    let b = e.currentTarget
    let btn = b.querySelector('.btn-cta-label')
    let text = btn.dataset.label + ' ? ' + '(' + btn.dataset.count + ')'

    notie.confirm({
        text: text,
        submitText: 'Confirmer',
        cancelText: 'Annuler',
        cancelCallback: () => {},
        submitCallback: () => batchSelection(b.dataset.selectActionUrl, b.dataset.rowTarget, b.dataset.selectAll)
    })
}

// confirmation après validation de la modale d'avertissement
const confirmInfoBatchSel = (e) => {
    $('#modal-info').modal('hide')

    let b = e.currentTarget
    let text = b.dataset.confirmText + ' (' + b.dataset.count + ')'
    notie.confirm({
        text: text,
        submitText: 'Confirmer',
        cancelText: 'Annuler',
        cancelCallback: () => {},
        submitCallback: () => batchSelection(b.dataset.selectActionUrl, b.dataset.rowTarget)
    })
}

const uploadBatchSel = (e) =>
    batchSelection(e.currentTarget.dataset.url, e.currentTarget.dataset.rowTarget, e.currentTarget.dataset.selectAll)

const getSelectedIds = (rowTarget, all = false) => {
    let ids = null
    if (all) {
        ids = -1
    } else {
        ids = []
        selectUtils.getSelectedRows().forEach((row) => {
            let arr = {}
            let hasValues = false
            rowTarget.split(',').forEach((target) => {
                row.querySelectorAll('[' + target + ']').forEach((el) => {
                    hasValues = true
                    let key = target.replace('data-', '')
                    if (key in arr) {
                        arr[key] = arr[key] + ',' + el.getAttribute(target)
                    } else {
                        arr[key] = el.getAttribute(target)
                    }
                })
            })

            if (hasValues) {
                ids.push(arr)
            }
        })
    }

    return ids
}

const batchSelection = (url, rowTarget, all) => {
    fetch(url, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ids: getSelectedIds(rowTarget, all) })
    })
        .then((response) => fetcher.handleResponse(response))
        .then((data) => {
            if (data !== undefined) {
                if (data.notification) {
                    notie.alert({
                        type: data.notification.type,
                        text: data.notification.text,
                        time: 3
                    })
                }
                refreshDataTable()
                selectUtils.toggleActionZone()
                selectAll.checked = false
            }
        })
}

const myToggle = (e) => toggleAll(e.currentTarget)

export const initBatchActions = (container) => {
    selectAll = document.querySelector('[data-select-all]')
    if (selectAll && !selectAll.disabled) {
        // need to refresh the instance just in case datatable has been
        // destroyed
        selectAll = document.querySelector('[data-select-all]')

        // uncheck if no row has been selected for this run
        if (selectUtils.countSelectedRows() === 0) {
            selectAll.checked = false
        }

        selectAll.removeEventListener('click', myToggle)
        selectAll.addEventListener('click', myToggle)
        // Append a container for each button that will host selection count
        selectUtils.configureButtons()
        // Bind action zone buttons
        batchSelectionInit()
        // Bind the expand to hiden button
        selectUtils.bindExpandToAllButton()
    }

    if (container.querySelector('.datatable-action-zone')) {
        if (container.querySelector('.datatable-action-zone').querySelectorAll('button').length > 0)
            batchSelectionInit()
    }
}
