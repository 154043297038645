import { triggerEvent } from '~/utils/base'

export const init = (container) => {
    toggleInvoiceConfig(container, '#organization_hasInvoiceConfig')
    // checkbox allowedDates all-day
    // toggleCheckDates(container, '#allowedDates-placeholder')
}

// Toggle main checkbox and sub checkbox (form address)
// Note that TomSelect instances has no attribute name
// TODO: Refactor, this is way too complicated to brain process
const toggleInvoiceConfig = (container, id) => {
    if (container.querySelector(id)) {
        let formAddress = container.querySelector('#invoiceConfigFormAddress')
        let checkboxAddressAccess = formAddress.previousElementSibling.querySelector('input')
        if (checkboxAddressAccess.checked) {
            formAddress.classList.remove('d-none')
        } else {
            formAddress.classList.add('d-none')
        }
        checkboxAddressAccess.addEventListener('change', (e) => {
            if (checkboxAddressAccess.checked) {
                formAddress.classList.remove('d-none')
            } else {
                formAddress.classList.add('d-none')
            }

            ;['input', 'select'].forEach((t) => {
                formAddress.querySelectorAll(t).forEach((i) => {
                    i.disabled = !checkboxAddressAccess.checked
                    // toggle instance of tomselect
                    if (i.id.endsWith('ts-control')) {
                        let id = i.id.replace('-ts-control', '')
                        let ts = document.querySelector('#' + id).tomselect
                        checkboxAddressAccess.checked ? ts.enable() : ts.disable()
                    }
                })
            })
        })

        let form = container.querySelector('#invoiceConfigForm')
        let checkboxAppAccess = container.querySelector(id)
        checkboxAppAccess.addEventListener('change', (e) => {
            ;['input', 'select'].forEach((t) => {
                form.querySelectorAll(t).forEach((i) => {
                    // don't process address fields
                    if (i.name.includes('[address]') === false) {
                        i.disabled = !checkboxAppAccess.checked
                        // uncheck address form too if global checkbox is uncheck
                        if (i.id == checkboxAddressAccess.id && !checkboxAppAccess.checked) {
                            checkboxAddressAccess.checked = false
                            triggerEvent(checkboxAddressAccess, 'change')
                        }
                        // toggle instance of tomselect (don't process tomselect address fields)
                        if (i.id.endsWith('ts-control') && i.id.includes('_address_') === false) {
                            let id = i.id.replace('-ts-control', '')
                            let ts = document.querySelector('#' + id).tomselect
                            checkboxAppAccess.checked ? ts.enable() : ts.disable()
                        }
                    }
                })
            })
        })
    }
}

export const toggleCheckDates = (container, id) => {
    if (container.querySelector(id)) {
        // check ALL block containing checkbox and not only the first one
        container.querySelectorAll(id).forEach((c) => {
            c.querySelectorAll('input[type="checkbox"]').forEach((input) => {
                if (!input.disabled && input.value.length == 10) {
                    input.addEventListener('change', (e) => {
                        input
                            .closest('.form-row')
                            .querySelectorAll('input')
                            .forEach((i) => (i.checked = e.target.checked && i.disabled === false))
                    })
                }
            })
        })
    }
}

export const getTopFilterValues = () => {
    let topEventFilters = []
    document
        .getElementById('top-filter-event')
        .querySelectorAll('input:checked')
        .forEach((checkbox) => {
            topEventFilters.push(checkbox.value)
        })
    return topEventFilters.join()
}

export const getServiceFilterValues = () => {
    let filters = []
    document
        .getElementById('service-filters')
        .querySelectorAll('input:checked')
        .forEach((checkbox) => {
            filters.push(checkbox.value)
        })
    return filters.join()
}
