export const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(find, 'g'), replace)
}

export const merge = (obj1, obj2) => ({ ...obj1, ...obj2 })

export const fetchFromObject = (obj, prop) => {
    if (typeof obj === 'undefined') {
        return false
    }

    var _index = prop.indexOf('.')
    if (_index > -1) {
        return fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1))
    }

    return obj[prop]
}

export const triggerEvent = (element, eventName) => {
    let event = document.createEvent('HTMLEvents')
    event.initEvent(eventName, false, true)
    element.dispatchEvent(event)
}
