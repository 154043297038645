import 'datatables.net-dt'
import language from './datatable/locale.fr'
import * as filter from './datatable/filter'
import * as columns from './datatable/columns'
import { initBatchActions, toggleRow, toggleExtend } from './datatable/select'
import * as formLogic from './form/logic'
import * as tab from './utils/tab'
import * as fetcher from './utils/fetcher'
import * as spinner from './utils/spinner'
import * as tooltip from './utils/tooltip'
import notie from 'notie'

let datatable = null
let currentModal = null
let parentModal = null
let dtEl = document.querySelector('[datatable]')
let firstLoad = true

export const refreshDataTable = () => datatable.draw('full-hold')
export const recreateDataTable = () => {
    datatable.destroy()
    // now we destroy everything we need to refresh the current node
    dtEl = document.querySelector('[datatable]')
    generateDataTable(dtEl)
}

// Header columns need to be adjusted when sidebar is minimized/expanded
// Note: DataTable setting "autoWidth" should (not?) do the same thing automatically
if (document.querySelector('.navbar-minimalize')) {
    document.querySelector('.navbar-minimalize')
        .addEventListener('click', () => refreshDataTable())
}

// generate headers columns and default order from HTML
const generateDataTable = (table) => {
    let settings = columns.generate()

    // displayStart and deferLoading are properties that need to be set
    // if we want to load data from another page than the first one
    let displayStart = 0
    let dtPage = 0
    let filters = new URLSearchParams(location.search)

    // page courante
    if (filters.has('page')) {
        dtPage = Number(filters.get('page')) - 1
        displayStart = (Number(filters.get('page')) - 1) * table.dataset.length
    }

    // les querystring des orders sont de la forme
    // Array("order[0][columns]", "2")
    // Array("order[0][dir]", "asc")
    let dtSort = settings.defaultOrder
    let orders = Array.from(filters).filter(([key, value]) => key.match(/order/))
    if (orders.length > 0) {
        let tmp = []
        orders.forEach((val, key) => {
            if (tmp.length === 2) {
                dtSort.push(tmp)
                tmp = []
            }

            tmp.push(val[1])
        })
        if (tmp.length === 2) dtSort.push(tmp)
    }

    // modal à ouvrir sur la liste
    // ie. &modalUrl=/organization/110/edit&modalTitle=Organisation
    if(filters.has('modalUrl')) {
         currentModal="#modal-app"
         fetchModalForm(filters.get('modalUrl'), filters.get('modalTitle'))
    }

    let pageScrollPos = 0

    datatable = $(table)
        .DataTable({
            autoWidth: false,
            columns: settings.columns,
            deferLoading: table.dataset.recordsTotal,
            displayStart: displayStart,
            language: language,
            lengthChange: false,
            order: dtSort,
            pageLength: table.dataset.length,
            processing: true,
            scrollCollapse: true,
            scrollX: true,
            scrollY: '55vh',
            searchDelay: 500,
            searching: false,
            serverSide: true,
            ajax: {
                url: table.dataset.url,
                complete: () => {
                    updateQueryString()
                    onComplete(dtEl)
                },
                // if request throw an error it is likely session has expired or there is an exception.
                // datatable wants we manage this on server side and send it back to the response,
                // for now we assume (in staging/prod env) the session has expired and no other exception would rise.
                error: (xhr, error, thrown) => {
                    // NOTE: si la session a expiré, nous ne sommes pas en mesure ici
                    // de le savoir, la 302 a déjà été consommé et le status code renvoi une 200.
                    // La seule façon abordable est de chercher un csrf token dans la réponse.
                    if (xhr.responseText.includes('_csrf_token')) {
                        notie.alert({
                            type: 'warning',
                            text: language.sessionExpired,
                            time: 3
                        })

                        window.location.reload(false)
                    } else if (['development', 'staging'].includes(process.env.NODE_ENV)) {
                        notie.alert({
                            type: 'error',
                            text: '[DEV] Something went wrong with Datatable response: ' + error,
                            time: 5
                        })
                    } else {
                        // this behaviour is in fact really annoying, datatable reloads eternally
                        // window.location.reload(false)
                    }
                }
            },
            rowCallback: function (row, data) {
                if (data.deleted) {
                    $(row).addClass('strikeout')
                }
                if(data.taken_into_account !== undefined && data.taken_into_account !== null) {
                    if(data.taken_into_account) {
                        $(row).addClass('taken_into_account')
                    } else {
                        $(row).addClass('to_take_into_account')
                    }
                }
            },
            preDrawCallback: () => {
                pageScrollPos = $('div.dataTables_scrollBody').scrollTop()
            },
            drawCallback: (settings) => {
                setTimeout(() => $('div.dataTables_scrollBody').scrollTop(pageScrollPos), 3)
                updatePageHeading(settings.json)
            }
        })
        .on('processing.dt', function (e, settings, processing) {
            if (processing) {
                if (firstLoad) {
                    firstLoad = false
                    document.querySelector('.dataTables_empty').classList.add('d-none')
                    document.querySelector('.dataTables_info').innerHTML = ''
                    document.querySelector('.dataTables_paginate').innerHTML = ''
                }
            }
        })
        .on('preXhr.dt', (e, settings, data) => {
            // apply filters
            filter.searchWithFilters(data)

            // add a custom query string "sort" to deal with annoying default DataTable indexed ordered columns
            // ie: sort by first column ascending then by second column descending would result to
            // sort: "column1,-column2"
            let sortColumns = []
            for (let i = 0; i < data.order.length; i++) {
                let col = data.order[i].column
                let dir = data.order[i].dir
                sortColumns.push((dir === 'desc' ? '-' : '') + data.columns[col].name)
            }
            data.sort = sortColumns.join()
        })
        .on('draw.dt', (e) => handleActionsConfirm(e.target))
        .on('dblclick', 'tr', (e) => {
            // pas de selection automatique au double-clic
            // window.getSelection().removeAllRanges()

            // s'il existe un attribut row-url, on redirige vers l'URL souhaité
            let el = e.currentTarget.querySelector('[data-row-url]')
            if (null !== el) {
                // si on double-clique n'importe où sur une ligne sauf sur un pictogramme <i> ou un lien <a>
                if (['td', 'b', 'span'].includes(e.target.localName)) {
                    window.location.href = el.dataset.rowUrl
                }
            }
        })
        .on('click', '[data-select-row]', (e) =>
            e.shiftKey ? toggleExtend(e.currentTarget) : toggleRow(e.currentTarget)
        )
    // coche la checkbox au clic sur la ligne ou sur la checkbox elle-meme
    // .on('click', 'tr', (e) => {
    //     let target = e.currentTarget
    //     let checkboxEl = e.currentTarget.querySelector('[data-select-row]')
    //
    //     // ne pas prendre en compte si aucune case a cocher ou si c'est un lien
    //     if(checkboxEl === null || ['a'].includes(e.target.localName))
    //         return
    //
    //     if('selectRow' in e.originalEvent.target.dataset === true) {
    //         target = e.originalEvent.target
    //
    //     } else {
    //         checkboxEl.checked = !checkboxEl.checked
    //     }
    //
    //     e.shiftKey ? toggleExtend(target) : toggleRow(target)
    // })

    filter.bindFilters(datatable)
    datatable.page(dtPage).draw(false)
}

/**
 * Mise à jour de la query string en fonction de la recherche courante.
 */
const updateQueryString = () => {
    // on supprime les propriétés qui ne nous servent pas
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
    let { columns, draw, start, search, ...qs } = datatable.ajax.params()

    // si nombre d'item par page est celui par défaut, on le vire aussi
    if(dtEl.dataset.defaultLength === qs.length) delete qs.length

    // sur celles qui restent on n'affiche pas celle qui sont vide (valeur par défaut)
    Object.keys(qs).forEach((k) => {
        if (typeof qs[k] === 'string' && qs[k] === '') delete qs[k]
    })

    // construction de la querystring
    let usp = new URLSearchParams($.param(qs))
    if((datatable.page() + 1) > 1) usp.set('page', datatable.page() + 1)
    usp.sort() // trie
    let q = usp.toString().length === 0 ? '' : '?' + usp.toString()

    // mise à jour de l'historique de navigation
    window.history.pushState('', '', location.pathname + q)
}

const updatePageHeading = (data) => {
    if (data === undefined) return

    if (data.pageHeading) {
        let newPage = document.createRange().createContextualFragment(data.pageHeading)
        let newPageHeading = newPage.querySelector('.page-heading')
        document.querySelector('.page-heading').innerHTML = newPageHeading.innerHTML

        // check if we need to disable/hide checkboxes of table
        let selectAll = document.querySelector('[data-select-all]')
        if (selectAll) datatable.columns(0).visible(!selectAll.disabled)

        onComplete(document.querySelector('.page-primary-actions'))
    }

    if (data.statistics) {
        document.querySelector('.statistics').innerHTML = data.statistics
    }
}

export const initFormModal = (data, title = null) => {
    let body = getModalBody()
    body.innerHTML = ''

    // display form (if no form available skip)
    if (data.view === undefined || data.view === null) {
        $(currentModal).modal('show')
        return
    }

    // header - update title
    if (title !== null) {
        let modalTitle = body.previousElementSibling.querySelector('.modal-title')
        modalTitle.dataset.title = title
        modalTitle.innerHTML = title
    }
    // body - inject view content
    body.innerHTML = data.view.content
    body.nextElementSibling.classList.add('d-none')

    // FIXME: some modal contains no form
    // however there is still some UI components that needs to be bound
    reloadUiComponents(body)

    // reload form components if view contains a form
    if (body.querySelector('form')) {
        // footer - cleanup and bind button in form
        body.nextElementSibling.classList.remove('d-none')
        body.nextElementSibling.innerHTML = ''
        // clone submit buttons in the footer and bind to the real ones
        body.querySelectorAll('button[type="submit"]').forEach((el) => {
            // Clone <button> element and display it on the modal footer
            let btnSubmit = el.cloneNode(true)
            body.nextElementSibling.appendChild(btnSubmit)
            // Ensure fake submit button is visible
            btnSubmit.classList.remove('d-none')
            // Bind the click event to the real submit button
            btnSubmit.addEventListener('click', (e) => {
                e.preventDefault()
                let targetId = e.target.id
                // si on ne clique pas sur le bouton (mais un autre élement dans le bouton)
                if (e.target.nodeName !== 'BUTTON') {
                    targetId = e.target.closest('button').id
                }
                body.querySelector('#' + targetId).click()
            })
        })

        // focus empty input targeted as autofocus
        body.querySelectorAll('input').forEach((input) => {
            if (input.autofocus && input.value == '') {
                input.focus()
            }
        })
    }

    // scrollback to the top of the div
    body.scrollTop = 0

    if (currentModal !== null) initModal()
}

const initModal = () => {
    // show modal
    // see: https://getbootstrap.com/docs/4.0/components/modal/#events
    $(currentModal).unbind('show.bs.modal')
    $(currentModal).unbind('hidden.bs.modal')
    $(currentModal)
        .modal('show')
        .on('show.bs.modal', (e) => {
            currentModal = '#' + e.currentTarget.id
            e.currentTarget.removeAttribute('data-submitted')

            let modalForm = e.currentTarget.querySelector('form')
            if(modalForm !== null && modalForm.name === 'batch_services_request_form') {
                if(modalForm.dataset.type === 'train') {
                    // see assets/js/form/logic/services_request.js
                    modalForm.dispatchEvent(new CustomEvent('reload-attendees-list'))
                }
            }
        })
        .on('hidden.bs.modal', (e) => {
            // reshow parent modal if we were in a submodal and user cancel or submit it
            if (e.target.id !== parentModal && parentModal !== null) {
                // let subForm = getModalBody().querySelector('form')
                // switch cursor to parent modal
                currentModal = '#modal-app' // + parentModal
                parentModal = null

                $(currentModal).modal('show')
            }
        })
}

const getModalBody = () => {
    let body = document.querySelector(currentModal)
    return body.querySelector('.modal-body')
}

export const reloadUiComponents = (body) => {
    tab.init(body)
    tooltip.init(body)
    rebindForm(body)

    body.querySelectorAll('[data-toggle="modal"]').forEach((el) => fetchModal(el))

    // prevent modal submission by pressing Enter on inputs
    // this could lead to unintentional behaviours
    $('.modal-content').keypress(function (e) {
        // except on WYSIWYG
        if (!e.target.classList.contains('ck-content') && e.key === 'Enter') {
            e.preventDefault()
        }
    })

    // hide back button everytime the modal is loaded
    let backBtn = document.querySelector('a.service-toggle-back')
    backBtn.classList.add('d-none')

    // additional logic for the current form
    formLogic.init(body)
}

const rebindForm = (body) => {
    let formElem = null

    if (body.querySelector('form') !== null) {
        formElem = body.querySelector('form')
    }

    if (formElem !== null) {
        formElem.addEventListener('submit', onSubmit)
        formElem.addEventListener('formdata', handleFormSubmission)
    }
}

export const unbindForm = (body) => {
    let formElem = null

    if (body.querySelector('form') !== null) {
        formElem = body.querySelector('form')
    }

    if (formElem !== null) {
        formElem.removeEventListener('submit', onSubmit)
        formElem.removeEventListener('formdata', handleFormSubmission)
    }
}

export const onComplete = (container) => {
    if (container === null) return

    // bind call to action
    container.querySelectorAll('[data-modal]').forEach((el) => fetchModal(el, el.dataset.title))

    // skip if no row is displayed
    if (datatable.data().count() === 0) return

    // init tooltip found on each cells
    tooltip.init(container)
    // bind checkboxes
    initBatchActions(container)
}

const updateParentModal = (el) => {
    // console.log('updateParentModal ', el.getAttribute('data-modal-parent'))
    parentModal = el.getAttribute('data-modal-parent')
}

const clickModal = (e) => {
    e.preventDefault()
    updateParentModal(e.currentTarget)
    currentModal = e.currentTarget.getAttribute('data-target')

    // if we go to submodal, set current url via attribute to use it later.
    // in case the user submit the form
    if (e.currentTarget.getAttribute('data-parent-url')) {
        let modatTargetNode = document.querySelector(currentModal)
        modatTargetNode.setAttribute('data-parent-url', e.currentTarget.getAttribute('data-parent-url'))
        // Logic needed to prefill the flatpickrs fields if detected
        // if (e.currentTarget.dataset.formPresetEffectiveStartAt) {
        //     modatTargetNode.setAttribute(
        //         'data-form-preset-effective-start-at',
        //         e.currentTarget.dataset.formPresetEffectiveStartAt
        //     )
        // }
        // if (e.currentTarget.dataset.formPresetEffectiveEndAt) {
        //     modatTargetNode.setAttribute(
        //         'data-form-preset-effective-end-at',
        //         e.currentTarget.dataset.formPresetEffectiveEndAt
        //     )
        // }
    }

    fetchModalForm(e.currentTarget.getAttribute('data-url'), e.currentTarget.dataset.title)
}
const fetchModal = (el) => {
    el.removeEventListener('click', clickModal)
    el.addEventListener('click', clickModal)
}

const fetchModalForm = (modalUrl, modalTitle = null) => {
    fetch(modalUrl)
        .then((response) => fetcher.handleResponse(response))
        .then((data) => {
            if (data.notification) {
                notie.alert({
                    type: data.notification.type,
                    text: data.notification.text,
                    time: 5
                })
            } else {
                initFormModal(data, modalTitle)
            }
        })
        .catch((error) => notie.alert({ type: 'error', text: error, time: 5 }))
}

const onSubmit = (e) => {
    e.preventDefault()
    // construct a FormData object, which fires the formdata event
    new FormData(e.target)
}

const handleFormSubmission = (e) => {
    let body = getModalBody()
    let form = e.target

    // Get the form data from the event object
    let data = e.formData
    let activeElementId = document.activeElement.id

    if (process.env.NODE_ENV === 'development') {
        for (var value of data) {
            console.log(value)
        }
        console.log('button activeElement is', document.activeElement)
    }

    // Pass the submitted button to the body of request
    // to let Symfony knows which one has been clicked.
    //
    // Chrome and Firefox have a different behaviour for activeElement.
    // Chrome will consider there is no activeElement if it's become disabled. Firefox don't care.
    // Since we want to disable it to avoid double click effect, do it right after this instruction.
    // Not before.
    data.append(document.activeElement.name, document.activeElement.value)

    // Prevent submit button to trigger more than once
    spinner.attach(document.activeElement)

    fetch(form.action, { method: form.method, body: data })
        .then((response) => fetcher.handleResponse(response))
        .then((json) => {
            // there is some errors in the form
            if (json.view) {
                // change form action if needed
                if (json.action) {
                    form.action = json.action
                }

                // replace the form content
                form.innerHTML = json.view.content

                reloadUiComponents(body)

                // everything looks good
            } else {
                $(currentModal).modal('hide')

                // reshow parent modal if we were in a submodal and user cancel or submit it
                if (currentModal !== parentModal && parentModal !== null) {
                    // let subForm = getModalBody().querySelector('form')
                    // switch cursor to parent modal
                    currentModal = '#modal-app' // + parentModal
                    parentModal = null

                    // si la modale enfant a été validé on déclenche le refresh dans la modale parente.
                    // let parentBody = getModalBody()

                    // Si on voulait rafraichir entierement la modal parente
                    // fetchModalForm(e.currentTarget.getAttribute('data-parent-url'))
                    // fetcher.refreshPart(subForm, parentBody.querySelector('form'))

                    console.log('do some update in form with theses datas', json)

                    // S'il existe seulement un champ a mettre à jour
                    if (json.field) {
                        let newForm = document.createRange().createContextualFragment(json.field.content)
                        let placeholder = document
                            .getElementById('modal-app')
                            .querySelector('#' + json.fieldName + '-placeholder')
                        placeholder.innerHTML = newForm.firstChild.lastElementChild.innerHTML
                        reloadUiComponents(placeholder)
                    }

                    // Enfin on affiche la modale
                    $(currentModal).modal('show')
                }

                if (currentModal != '#modal-app') {
                    document.querySelector(currentModal).setAttribute('data-submitted', '1')
                } else {
                    datatable.draw('full-hold')
                }

                // Short fix to show the "add services" when an attendee has been added on the event
                if (activeElementId === 'event_attendee_save') {
                    let primaryAction = document.querySelector('ul.page-primary-actions>li>a.disabled')
                    if (primaryAction !== null) {
                        if (primaryAction.classList.contains('disabled')) {
                            primaryAction.classList.remove('disabled')
                        }
                    }
                }
            }
        })
        .catch((error) => {
            rebindForm(body)
            notie.alert({ type: 'error', text: error, time: 5 })
        })
        .finally(() => {
            // reenable submit buttons
            document.querySelectorAll('.modal-footer button').forEach((btn) => spinner.detach(btn))
        })
}

// confirm action when clicking on an action button in the dropdown list
const handleActionsConfirm = (table) => {
    table.querySelectorAll('.form-action').forEach((el) => {
        el.querySelector('button').addEventListener('click', (e) => {
            e.preventDefault()
            notie.confirm({
                text: el.dataset.message,
                submitText: el.dataset.submitText,
                cancelText: el.dataset.cancelText,
                cancelCallback: () => notie.alert({ type: 3, text: el.dataset.messageCancel, time: 2 }),
                submitCallback: () => submitForm(el, el.dataset.method)
            })
        })
    })
}

const submitForm = (form, method = 'post') => {
    fetch(form.dataset.action, {
        method: method,
        body: new URLSearchParams(new FormData(form))
    })
        .then((response) => fetcher.handleResponse(response))
        .then((data) => {
            if (data.notification) {
                notie.alert({
                    type: data.notification.type,
                    text: data.notification.text,
                    time: 5
                })
            } else {
                datatable.draw('full-hold')
            }

            if (data.refreshDataTable) {
                datatable.draw('full-hold')
            }
        })
        .catch((error) => notie.alert({ type: 'error', text: error, time: 5 }))
}

document.addEventListener('DOMContentLoaded', () => {
    if (dtEl !== null) {
        generateDataTable(dtEl)
    }
})
