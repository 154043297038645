import { onComplete } from './datatable'
import { handleClick } from './utils/link'
import { initChoices } from './form/choice'
import { cookie } from './utils/cookie'
import * as notification from './utils/notification'

document.addEventListener('DOMContentLoaded', () => {
    if (document.body.classList.contains('not-logged')) return

    // TODO: la logique d'affichage des modales de l'app se trouve dans le script 'datatable.js'
    // onComplete bind les modales qu'il trouve dans le scope qu'on lui donne.
    // Déporter cette logique dans un script 'modal.js' qui se chargerai de gérer ça.

    if (document.querySelector('.project-manager')) {
        onComplete(document.querySelector('.project-manager ul.services'))
    }

    // on init les listes déroulante qui ont l'attribut "data-choices"
    initChoices(document.body)
    // on init les liens qui ont l'attribut "data-action"
    document.querySelectorAll('[data-action]').forEach((input) => handleClick(input))

    // on init le centre de notification
    notification.init()

    // stockage de la pref utilisateur de la sidebar (dépliée ou repliée)
    // inspinia gère le dépliage/repliage de la sidebar, notre event est déclenché juste après
    // d'où la négation de la classList
    document.querySelector('.navbar-minimalize').addEventListener('click', (e) => {
        cookie('mini-navbar', !document.body.classList.contains('mini-navbar'), {
            path: '/'
        })
    })
})
