// Filters for DataTable
import { refreshDataTable, recreateDataTable } from '~/datatable'
import * as fetcher from '~/utils/fetcher'
import * as checkbox from '~/form/checkbox'
import { cookie } from '~/utils/cookie'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'
import flatpickr from 'flatpickr'
import { settingsDefault } from '~/form/calendar'
import * as utils from '~/utils/base'

dayjs.extend(isoWeek)
dayjs.extend(utc)

let filtersEl,
    filterTerms,
    filterDatepicker,
    filterPeriods,
    razBtn = null
let dateRangeFilter = null

const reloadDatatable = (url, cls) => {
    fetch(url)
        .then((response) => fetcher.handleResponse(response))
        .then((data) => {
            // on met a jour le DOM de la datatable
            let targetNode = document.querySelector(cls).nextElementSibling
            let newNode = document.createElement('div')
            newNode.innerHTML = data.table.content
            targetNode.parentNode.replaceChild(newNode, targetNode)
            // et on déclenche une réinitialisation
            recreateDataTable()
        })
        .catch((error) => console.error(error))
}

export const toggleServices = (e) => {
    let serviceFilters = document.getElementById('service-filters')
    let input = e.currentTarget
    let serviceType = null
    if (input.dataset.checked === 'true') {
        input.checked = false
        Array.prototype.forEach.call(serviceFilters.getElementsByTagName('input'), input => (input.dataset.checked = 'false'))
        serviceType = ''
    } else {
        Array.prototype.forEach.call(serviceFilters.getElementsByTagName('input'), input => (input.dataset.checked = 'false'))
        input.checked = true
        input.dataset.checked = 'true'
        serviceType = input.value
    }
    // met a jour la query string datatable (sans rechargement de page)
    let searchParams = new URLSearchParams(location.search)
    searchParams.set('service_filters', serviceType)
    let url = new URL(location.origin + '/fetch/cols?' + searchParams.toString())
    reloadDatatable(url, '#service-filters')
}

// Trigger a search with available filters
export const searchWithFilters = (data) => {
    if (filterTerms) {
        data.terms = filterTerms.value
    }
    filtersEl
        .querySelectorAll('[filter-dropdown]')
        .forEach((el) => (data[el.dataset.name] = el.options[el.selectedIndex].value))
    filtersEl.querySelectorAll('[filter-checkbox]').forEach((el) => (data[el.dataset.name] = el.checked ? 1 : 0))

    if (dateRangeFilter !== null) {
        data.searchByDateRange = []
        dateRangeFilter.selectedDates.forEach((d) => data.searchByDateRange.push(dayjs(d).format('YYYY-MM-DD')))
    }

    if (null !== document.getElementById('service-filters')) {
        // NOTE: 'service_filters' should match Constants::SERVICE_FILTERS
        data.service_filters = checkbox.getServiceFilterValues()
    }
    // check also the top event filter
    if (null !== document.getElementById('top-filter-event')) {
        // NOTE: 'top_event' should match Constants::TOP_FILTERS_EVENT
        data.top_event = checkbox.getTopFilterValues()
    }
}

export const bindFilters = (datatable) => {
    filtersEl = document.querySelector('.form-filters')
    if (filtersEl) {
        filterTerms = filtersEl.querySelector('.filter-terms')
        filterDatepicker = filtersEl.querySelector('.filter-datepicker')
        filterPeriods = filtersEl.querySelector('.filter-periods')
        razBtn = filtersEl.querySelector('.filter-raz')
    }

    if (filterTerms) {
        initTerms(datatable)
    }
    if (filterDatepicker) {
        dateRangeFilter = initDatepicker(datatable)
        if (filterPeriods) {
            initPeriods(dateRangeFilter)
        }
    }
    filtersEl.querySelectorAll('[filter-dropdown]').forEach((el) => {
        // set default pager length
        if (el.dataset.name == 'length') {
            datatable.page.len(el.options[el.selectedIndex].value)
        }

        el.addEventListener('change', () => {
            // filter-dropdown managing the pager is different than the others
            if (el.dataset.name == 'length') {
                datatable.page.len(el.options[el.selectedIndex].value).draw()

            } else if (el.dataset.name == 'eventMetric') {
                let searchParams = new URLSearchParams() // reset de la query string
                searchParams.set(el.dataset.name, el.options[el.selectedIndex].value)
                let url = new URL(location.origin + '/fetch/columns/' + el.dataset.name + '?' + searchParams.toString())
                // Note: il faut mettre à jour l'historique de navigation pour les métriques car elles ont des colonnes différentes.
                // on ne veut pas conserver le tri sur une colonne inexistante par ex.
                window.history.pushState('', '', location.pathname + '?' + searchParams.toString())
                reloadDatatable(url, '.statistics')

            } else {
                datatable.draw()
            }
        })
    })
    filtersEl
        .querySelectorAll('[filter-checkbox]')
        .forEach((el) => el.addEventListener('change', () => datatable.draw()))

    if (razBtn) {
        initRaz(datatable)
    }

    // filtres suplémentaires sur les types de service de certaines vues
    // (service_requested, delivery, delivery_fix)
    // boutons radios type "décochables"
    let serviceFilters = document.getElementById('service-filters')
    if (null !== serviceFilters) {
        Array.prototype.forEach.call(serviceFilters.getElementsByTagName('input'), input => {
            // input.removeEventListener('click', toggleServices)
            input.addEventListener('click', toggleServices, { once: true})
        })
    }

    // gestion des boutons genéraux de filtre sur les évènements passé/encours/a
    // venir. boutons case à cocher.
    if (null !== document.getElementById('top-filter-event')) {
        document.getElementById('top-filter-event').addEventListener('change', () => {
            cookie('top_event', checkbox.getTopFilterValues(), { path: '/' })
            refreshDataTable()
        })
    }
}

const initTerms = (datatable) => {
    let timer = null
    filterTerms.addEventListener('keydown', (e) => {
        // console.log(e.keyCode)
        // Trigger a search for all chars except below
        if (![
            9,  // <TAB>
            13, // <CR>
            37, // <Left>
            38, // <Up>
            39, // <Right>
            40, // <Down>
            67, // <C-c>
            86, // <C-v>
            91  // <Super>
        ].includes(e.keyCode) && e.target.value.length > 0) {
            clearTimeout(timer)
            timer = setTimeout(() => datatable.search(e.target.value).draw(), 500)
        }
    })
    filterTerms.addEventListener('paste', (e) => {
        clearTimeout(timer)
        timer = setTimeout(() => datatable.search(e.target.value).draw(), 500)
    })
    filterTerms.addEventListener('keypress', (e) => {
        if (e.keyCode == 13) e.preventDefault()
    })
}

// reset all filters but pager
const initRaz = (datatable) => {
    razBtn.addEventListener('click', (e) => {
        e.preventDefault()

        // check if we need to reset filters first
        let isEmpty = Array.from(filtersEl.elements).every(el => {
            if(el.type === 'checkbox') {
                return !el.checked
            } else if(el.nodeName === 'INPUT') {
                return el.value === ''
            } else if(el.nodeName === 'SELECT') {
                // on ne prend pas en compte le nb d'item par page
                if(el.dataset.name === 'length') return true
                else if(el.dataset.name === 'eventMetric') return true
                return el.selectedIndex === 0
            }
            return true
        })
        if(isEmpty) return

        filterTerms.value = ''

        filtersEl.querySelectorAll('[filter-dropdown]').forEach((el) => {
            if (el.dataset.name != 'length' && el.dataset.name != 'eventMetric')
                el.selectedIndex = 0
        })
        filtersEl.querySelectorAll('[filter-checkbox]').forEach((el) => (el.checked = false))

        // reset to the default order
        datatable.order([])

        // datepicker trigger already a draw on datatable
        if (filterDatepicker) {
            // filterFastDate.selectedIndex = 0
            dateRangeFilter.clear()
        } else {
            datatable.draw()
        }
    })
}

const initDatepicker = (datatable) => {
    // bind clear button
    if(filterDatepicker.nextElementSibling !== undefined) {
        filterDatepicker.nextElementSibling.addEventListener('click', () => {
            // clear only if there are dates
            if(dateRangeFilter.selectedDates.length > 0) dateRangeFilter.clear()
        })
    }

    return flatpickr(
        filterDatepicker,
        utils.merge(settingsDefault, {
            mode: 'range',
            defaultDate:
                'defaultRange' in filterDatepicker.dataset ? filterDatepicker.dataset.defaultRange.split(',') : null,
            onReady: (dateObj, dateStr, fp) => {
                if(dateObj.length === 1) fp.altInput.value = fp.altInput.value + ' ↦'
                if(dateObj.length === 2) fp.altInput.value = fp.altInput.value.replace('au', '↹')
                // autoclose calendar container if the user has selected a date
                // and leave the container
                // fp.calendarContainer.addEventListener('mouseleave', () => fp.close())
            },
            onChange: (dateObj, dateStr, fp) => {
                if(dateObj.length === 1) {
                    fp.altInput.value = fp.altInput.value + ' ↦'
                    fp.calendarContainer.addEventListener('mouseleave', () => fpMouseLeave(fp, datatable), { once: true })
                } else {
                    if(dateObj.length === 2) {
                        fp.altInput.value = fp.altInput.value.replace('au', '↹')
                    }
                    fp.calendarContainer.removeEventListener('mouseleave', fpMouseLeave(fp, datatable))
                    datatable.draw()
                }
            },
            onClose: (dateObj, dateStr, fp) => allowSingleDateRange(fp)
        })
    )
}

const fpMouseLeave = (fp, datatable) => {
    fp.close()
    datatable.draw()
}

const allowSingleDateRange = (fp) => {
    if (fp === undefined) {
        return
    }

    if (fp.selectedDates.length == 1) {
        fp.setDate([fp.selectedDates[0]])
        fp.altInput.value = fp.altInput.value + ' ↦'
    }
}

const initPeriods = (datepicker) => {
    filterPeriods.addEventListener('change', (e) => {
        e.preventDefault()
        let option = filterPeriods.options[e.target.selectedIndex]
        let startDay,
            endDay = null
        startDay = dayjs().startOf(option.value).utc()
        endDay = dayjs().endOf(option.value).utc()
        datepicker.selectedDates = [startDay.$d, endDay.$d]
        datepicker.setDate(datepicker.selectedDates, true)
    })
}
