;(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined'
        ? (module.exports = factory())
        : typeof define === 'function' && define.amd
        ? define(factory)
        : ((global = global || self), (global.cleanDatePlugin = factory()))
})(this, function () {
    'use strict'
    var __assign = function () {
        __assign =
            Object.assign ||
            function __assign(t) {
                for (var s, i = 1, n = arguments.length; i < n; i++) {
                    s = arguments[i]
                    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p]
                }
                return t
            }
        return __assign.apply(this, arguments)
    }

    var defaultConfig = {
        tooltip: 'Clean',
        toInitial: true,
        inside: true,
        icon: 'X', // if FontAwesome is loaded, "<i class='fas fa-eraser'></i>" looks better,
        class: ''
    }

    function cleanDatePlugin(pluginConfig) {
        var config = __assign({}, defaultConfig, pluginConfig)
        var cleanButton
        var cleanButtonCSSClass = 'flatpickr-clean'
        return function (fp) {
            if (fp.config.noCalendar || fp.isMobile) return {}
            return {
                onReady: function () {
                    let inputHeight = parseInt(window.getComputedStyle(fp._input).getPropertyValue('height'), 10)
                    let inputBorderTop = parseInt(
                        window.getComputedStyle(fp._input).getPropertyValue('border-top-width'),
                        10
                    )
                    let inputBorderBottom = parseInt(
                        window.getComputedStyle(fp._input).getPropertyValue('border-bottom-width'),
                        10
                    )
                    let inputPaddingTop = parseInt(
                        window.getComputedStyle(fp._input).getPropertyValue('padding-top'),
                        10
                    )
                    let inputPaddingBottom = parseInt(
                        window.getComputedStyle(fp._input).getPropertyValue('padding-bottom'),
                        10
                    )
                    let inputPaddingHoriz = parseInt(
                        window.getComputedStyle(fp._input).getPropertyValue('padding-right'),
                        10
                    )
                    let ltr = window.getComputedStyle(fp._input).getPropertyValue('direction') === 'ltr'
                    //let inputFontSize = window.getComputedStyle(fp._input).getPropertyValue('font-size');

                    cleanButton = fp._createElement('button', (cleanButtonCSSClass + ' ' + config.class).trim())
                    cleanButton.type = 'button'
                    cleanButton.title = config.tooltip
                    cleanButton.innerHTML += config.icon
                    cleanButton.addEventListener('click', function () {
                        fp.clear(true, config.toInitial)
                    })
                    if (config.inside) {
                        // button INSIDE input field
                        let totalHeight = inputHeight + inputPaddingTop + inputPaddingBottom
                        let dimX = totalHeight - inputBorderTop - inputBorderBottom
                        let dimY = dimX
                        cleanButton.style.width = dimX + 'px'
                        // cleanButton.style.height = dimY + 'px';
                        if (ltr) {
                            // Left to Right
                            // cleanButton.style.left = -(totalHeight + inputPaddingHoriz) + 'px';
                            // cleanButton.style.marginRight = -dimX+'px';
                            // fp._input.style.paddingRight = totalHeight + inputPaddingHoriz + 2 + 'px'
                        } else {
                            // Right to Left
                            inputPaddingHoriz = parseInt(
                                window.getComputedStyle(fp._input).getPropertyValue('padding-left'),
                                10
                            )
                            fp._input.style.paddingLeft = totalHeight + inputPaddingHoriz + 2 + 'px'
                            fp._input.style.left = -(totalHeight + inputPaddingHoriz) + 'px'
                            fp._input.style.position = 'relative'
                        }
                    } else {
                        // button OUTSIDE (next to) input field
                        let dimX =
                            inputHeight + inputBorderTop + inputBorderBottom + inputPaddingTop + inputPaddingBottom
                        let dimY = dimX
                        cleanButton.style.width = dimX + 'px'
                        cleanButton.style.height = dimY + 'px'
                    }

                    if (ltr) {
                        // Left to Right
                        let divEl = fp._createElement('div', 'input-group-append')
                        divEl.appendChild(cleanButton)
                        fp._input.parentNode.insertBefore(divEl, fp._input.nextSibling)
                    } else {
                        // Right to Left
                        fp._input.parentNode.insertBefore(cleanButton, fp._input)
                    }
                    fp.loadedPlugins.push('cleanDate')
                }
            }
        }
    }
    return cleanDatePlugin
})
