export const generate = () => {
    let columns = []
    let defaultOrder = []

    // NOTE: il faut reparcourir le DOM a chaque demande
    // les colonnes ont peut-être été regénéré
    document.querySelector('[datatable]').querySelectorAll('th').forEach((el, index) => {
        columns.push({
            data: el.dataset.column,
            name: el.dataset.sortColumn,
            orderable: el.dataset.orderable !== undefined,
            visible: el.dataset.hidden === undefined
        })
        if (el.dataset.defaultOrder) defaultOrder.push([index, el.dataset.defaultOrder])
    })

    return {
        columns: columns,
        defaultOrder: defaultOrder
    }
}

export default {
    generate
}
